import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal,
  Alert
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import { Auth } from '../../Services/Auth';
import { NotificationManager } from 'react-notifications';
import FbLogin from '../FBLogin';
import GoogleLogin from '../GoogleLogin'
import { setAppState } from '../../helpers/app_state';
import ForgotPassword from '../ForgotPassword';

function FacilitatorLogin() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  let type = 'facilitator'

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    Auth.login({ email: event.target.email.value, password: event.target.password.value, type: type }).then(
      (data) => {
        if (!data.errors) {
          setAppState(data)
          NotificationManager.success(data.message, 'success!', 2000);
          setMessage(data.message)
          history.push('/facilitator-dashboard');
        } else {
          NotificationManager.error(data.message, 'error!', 2000);
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  };
  const afterLoginSuccess = () => {
    history.push('/facilitator-dashboard');
  }
  const afterLoginFailed = (socialData) => {
    history.push({
      pathname: '/facilitator-signup/',
      state: socialData
    });
  }
  return (
    <>
      <Header />
      <section style={{ height: '100vh', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/login-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row className="justify-content-end">
              <Col md={5}>
                <div className="login-box">
                  <div className="tab-menu seprator">
                    <ul>
                      <li className="active" style={{ width: "100%" }}>
                        <a style={{ fontSize: '25px' }} href="/facilitator">
                          Facilitator
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="login-box-body">
                    <div className={active ? "d-block alert alert-danger d-flex align-items-center" : "d-none"} role="alert">
                      <img src="/images/cross.png" alt="" width="20" />
                      <div className="ms-2">
                        Incorrect username or password
                      </div>
                    </div>
                    <h4 className="text-center">Log in to your account</h4>
                    {/* <div className="login-with">
                      <ul>
                        <li>
                          <GoogleLogin type={type} afterLoginSuccess={afterLoginSuccess} afterLoginFailed={afterLoginFailed} />
                        </li>
                        <li>
                          <FbLogin type={type} afterLoginSuccess={afterLoginSuccess} afterLoginFailed={afterLoginFailed} />
                        </li>
                      </ul>
                    </div>
                    <div className="or">OR</div> */}
                    {/* <p>Thank you for being a part of the Dasya. Log in using the ID given to you by Dasya at the time of registration.</p> */}
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Row>
                        <Form.Group className="form-group mb-3" as={Col} md="12" controlId="validationCustom01">
                          <Form.Control
                            required
                            type="email"
                            name="email"
                            placeholder="Facilitator Email"
                            defaultValue=""
                          />
                          <span className="form-icon">
                            <img src="/images/user-icon.png" alt="" />
                          </span>
                        </Form.Group>
                        <Form.Group className="form-group mb-3" as={Col} md="12" controlId="validationCustom01">
                          <Form.Control
                            // value={this.state.password}
                            type="password"
                            name="password"
                            placeholder="Password"
                            defaultValue=""
                          />
                          <span className="form-icon">
                            <img src="/images/pass-icon.png" alt="" />
                          </span>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Check className="remember"
                            type="checkbox"
                            label="Remember Me"
                            id="remember"
                          />
                        </Col>
                        <Col md={6} className="text-end">
                          <a href="javascript:void(0)" className="forgot" onClick={handleShow}>Forgot Password?</a>
                        </Col>
                      </Row>
                      <Stack className="col-md-12 mt-4 mb-3">
                        <Button variant="primary" type="submit">Log in To Your Account</Button>
                      </Stack>
                      <p className="not-member"> Use the invite link shared in your email
                        {/* <Link to="/facilitator-signup/">Register Now</Link> */}
                      </p>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <ForgotPassword show={show} handleClose={handleClose} />
      {/* <Modal show={show} onHide={handleClose}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <img src="/images/lock-pass-icon.png" alt="" width="79.4" />
          </div>
          <p className="modal-text text-center">
            Unfortunately we are unable to validate your account. Since this is a single sign-on kindly contact your child’s school to reset your password.
          </p>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose} className="px-5">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}
      <Footer />
    </>
  )
}

export default FacilitatorLogin
