import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TutorSideMenu from "./TutorSideMenu";
import { FecthData } from '../../Services/FetchData';
import TutorSessionCalendar from './TutorSessionCalendar';
import _ from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatDate, timeFormate } from '../../helpers/utils';
import config from '../../config';
import Countdown from 'react-countdown';
import MyScheduleUI from '../common/MyScheduleUI';
import NotificationUI from '../common/NotificationUI';
import { getResourceUrl } from '../../Services/CommanFunctions';

function TutorMySchedule() {
  const user = JSON.parse(localStorage.getItem('user'))
  const selectedDay = val => {
    const date = new Date(val)
    selectDate(formatDate(date))
  };
  const [sessions, setSessions] = useState([])
  const [selectedDate, selectDate] = useState(formatDate(new Date()))
  const [dateWiseSessions, setDateWiseSessions] = useState([])
  const history = useHistory()
  const getTutorSession = () => {
    FecthData.getData('tutor/get_tutor_session/' + user?.tutor?._id+'?upcoming='+true).then(
      (data) => {
        setSessions(data?.data)
      }
    )
  }
  const getTutorSessionDateWise = () => {
    FecthData.getData('tutor/get_tutor_session/' + user?.tutor?._id+'?date='+selectedDate).then(
      (data) => {
        setDateWiseSessions(data?.data)
      }
    )
  }
  useEffect(() => {
    getTutorSession()
  }, [])
  const getDateWiseSession = () => {
    if (selectedDate) {
      const tutorSessions = _.filter(sessions, session => {
        if (session.date == new Date(selectedDate).toISOString()) {
          return session
        }
      })
      setDateWiseSessions(tutorSessions)
    }
  }
  useEffect(() => {
    getTutorSessionDateWise()
  }, [selectedDate])
  const logout = () => {
    localStorage.clear()
    history.push('/tutor')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <TutorSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <MyScheduleUI sessions={sessions} role={'tutor'} />
              {/* <Col md={12}>
                <div className="tutor-list mt-3">
                  {sessions?.map(session => <><div className="class-date-time">
                    <ul>
                      <li>
                        {timeFormate(session?.start_time)}- {timeFormate(session?.end_time)}
                      </li>
                      <li>
                        {new Date(session?.date).toLocaleDateString('en-us', { weekday: "long", month: "short", day: "numeric" })}
                      </li>
                    </ul>
                  </div>
                    <div className={`tutor-item ${new Date(session?.start_time).getDate() == new Date().getDate() ? 'active' : ''}`}>
                      <div className="tutor-wrap">
                        <div className="tutor-avatar">
                          <img src={session?.tutor_id?.user_id?.propic ? session?.tutor_id?.user_id?.propic : "images/avatar-male.jpg"} alt="" />
                        </div>
                        <div className="tutor-subject">
                          {/* <h6>ID: 567876</h6> *
                          <h5>Subject: {session?.subject?.title} </h5>
                          {/* <p>Facilitator: Jane Doe | Session: Virtual</p> *
                          {new Date(session?.start_time).getDate() == new Date().getDate() ? <Link to="/tutor-join-class" className="btn btn-success px-5 mt-3">Start Class Now</Link>
                            : <Button variant="light" className="px-5 mt-3"> Time Left <Countdown

                              date={session?.start_time}

                              daysInHours="true"

                            /></Button>}
                        </div>
                      </div>
                      <div className="tutor-icon">
                        <img src={'images/' + session?.subject?.title?.toLowerCase() + '.png'} alt="" />
                        {/* <img src="images/algebra.png" alt="" /> *
                      </div>
                    </div></>)}
                  {/* <div className="class-date-time">
                    <ul>
                      <li>
                        12:00 PM-1:00PM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 2:55:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/geometry.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        05:00 PM-06:00 PM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>Geography Chapter 4: Cultural Geography</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 5:30:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/calcuus.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        10:00 AM-10:30 AM
                      </li>
                      <li>
                        Tuesday, Aug 07
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 14:55:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/geometry.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        12:00 PM-1:00PM
                      </li>
                      <li>
                        Tuesday, Aug 07
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>Geography Chapter 4: Cultural Geography</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 24:30:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/calcuus.png" alt="" />
                    </div>
                  </div> *
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic?getResourceUrl(user?.propic):"images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.tutor?.org_type == 'school' ? user?.tutor_profile?.school_name : user?.tutor_profile?.company_name}</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <TutorSessionCalendar dateWiseSessions={dateWiseSessions} selectedDay={selectedDay} />

          </div>
        </div>
      </section>

    </>
  )
}

export default TutorMySchedule
