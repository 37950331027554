import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { School } from '../../Services/School';
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import ActionButton from '../ActionButton';
import RemoveImage from '../RemoveImage';

const SchoolSignupStepOne = ({ nextStep, handleImage, handleFormData, handleSchool, values }) => {
  const [value, setValue] = useState(values.school_name);
  const [validated, setValidated] = useState(false);
  const [school, setSchool] = useState('');
  const [error1, setError1] = useState();
  const [error, setError] = useState();
  const [error2, setError2] = useState();
  const [file, setFile] = useState();
  function handleChange(e) {

    setFile(URL.createObjectURL(e.target.files[0]));
    handleImage(e.target.files[0])
    handleSchool('logo_url', URL.createObjectURL(e.target.files[0]))
  }
  const removeImage = () => {
    handleSchool('logo_url', '')
  }
  const submitFormData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (validateForm()) {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }

      setValidated(true);
      if (
        validator.isEmpty(values.display_logo)
      ) {
        setError1(true);
      } else {
        nextStep();
      }
    }

  };

  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["school_name"]) {
      formIsValid = false;
      errors["school_name"] = "*Please select Your school.";
    }

    if (!fields["display_logo"]) {
      formIsValid = false;
      errors["display_logo"] = "*Please select display OR not.";
    }
    setError2(errors)
    return formIsValid;


  }

  useEffect(() => {
    School.getSchool().then(
      (data) => setSchool(data),
      (error) => setError(error.toString())
    )
  }, [])
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Account Creation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Select Profile Image</h4>
                    <p>Your profile image is used as the icon for your personal space and to represent you in Dasya</p>
                    <div className="signup-step">
                      <ul>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Row>
                        <Col md={12}>
                          <div className="upload-profile">
                            <div className="upload-profile-img">
                              {values.logo_url && <span><img src={values.logo_url} alt="" style={{ objectFit: 'cover', height: '170px', width: '170px' }} /></span>}{!values.logo_url && <span><img style={{ objectFit: 'cover', height: '170px', width: '170px' }} src="images/no_img.png" alt="" /></span>}
                            </div>
                            <div className="upload-icon">
                              {values.logo_url ? <RemoveImage isHaveImage={values.logo_url} handleClick={removeImage} /> : <><img src="images/camera-icon.png" alt="" width="78" />
                                <input type="file" name="logo" onChange={handleChange} />
                                <Form.Control.Feedback type="invalid">
                                  Please chosse a image
                                </Form.Control.Feedback></>}
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-group my-4">
                            <Autocomplete className="search"
                              items={school?.data || []}
                              shouldItemRender={(item, value
                              ) => item.school_name.toLowerCase()
                                .indexOf(value.toLowerCase()) > -1}
                              getItemValue={item => item.school_name}
                              renderItem={(item, isHighlighted) =>
                                <div style={{
                                  background: isHighlighted ?
                                    '#eee' : 'white',
                                  padding: '5px 10px',
                                }}
                                  {...item} key={item._id}>
                                  {item.school_name}
                                </div>
                              }
                              defaultValue={values.school_name}
                              value={value}
                              onChange={(e) => {
                                setValue(e.target.value)
                                handleFormData(e)
                              }}
                              onSelect={(val, item) => {
                                setValue(val)
                                handleSchool('school', item)
                                handleSchool('school_id', item._id)
                                handleSchool('school_name', item.school_name)
                                handleSchool('school_code', item.school_code)
                                handleSchool('school_street', item.street)
                                handleSchool('school_city', item.city)
                                handleSchool('school_state', item.state)
                                handleSchool('school_zip', item.zip_code)
                              }}
                              inputProps={{
                                style: {
                                  width: '100%',
                                  background: '#fff',
                                  boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                                  width: '100%',
                                  color: '#000',
                                  borderRadius: 10,
                                  minHeight: 52,
                                  border: '1px solid #E9ECF4',
                                  fontSize: 14,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                },
                                name: 'school',
                                placeholder: 'Search by school name',
                                required: true
                              }}
                              wrapperStyle={{
                                width: '100%',
                              }}
                            />
                            <Button variant="primary" type="submit" className="search-btn">
                              <img src="images/search-icon.png" alt="" width="22" />
                            </Button>
                            <span className="err">{error2?.school_name}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check className="text-grey" type="radio" name="display_logo" id="flexRadioDefault1" defaultValue="yes" onChange={handleFormData("display_logo")} checked={values.display_logo === "yes"} label="Display my image on my profile" />
                          </Form.Group>
                          <span className="err">{error2?.display_logo}</span>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                            <Form.Check className="text-grey" type="radio" name="display_logo" id="flexRadioDefault2" defaultValue="no" onChange={handleFormData("display_logo")} checked={values.display_logo === "no"} label="Don’t display my image on my profile" />

                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="signup-back-btn">
                        <Link to="/school" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[values?.school_id, values?.display_logo == 'yes' ? values?.logo_url : 'No']} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default SchoolSignupStepOne
