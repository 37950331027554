export const buildUser = (data) => {
    const obj = {
        username: data ? data.email ? data.email.replace(/.com|@gmail/g, "") : "" : '',
        logo_url: "",
        first_name: data ? data.given_name ? data.given_name : "" : "",
        last_name: data ? data.family_name ? data.family_name : "" : '',
        name: data ? data.name ? data.name : '' : '',
        email: data ? data.email ? data.email : "" : '',
        source: data ? data.source : null
    }
    if (data && data.source == 'FACEBOOK') {
        obj.username = 'facebook-' + data.id
        obj.name = data.name
        obj.logo_url = data.picture.data.url
    }
    return obj

}
export const userInfo = (data) => {

    const userData = {
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        status: data?.status,
        user_role: data?.user_role,
    }
    if (userData?.name) {
        const nameArr = userData?.name.split(" ")
        userData.first_name = nameArr[0]
        userData.last_name = nameArr[1]
    }

    userData.display_logo = "yes"
    if (data.user_role == 'student' || data.user_role == 'tutor') {
        userData.school_id = data.school_id
    }
    if (data.user_role == 'student') {
        userData.student_code = data.student_code
        userData.guardian_email = data.guardian_email
        userData.academic_year = data.academic_year
        userData.grade_id = data.grade_id
        userData.gender_id = data?.gender_avatar
        userData.id = data._id

    } if (data.user_role == 'tutor') {
        userData.tutoring_experience = data.tutoring_experience
        userData.highest_education_background = data.highest_education_background
        userData.about = data.about
        userData.timezone = data.timezone
        userData.subjects = data.subjects
        userData.school_id = data.school_id
        userData.id = data._id

    }
    if (data.user_role == 'parent' || data.user_role == 'facilitator') {
        userData.id = data.id
        userData.student_id = data.student_id
        userData.facilitator_id = data.facilitator_id
        // userData.content = data.content
    }
    if (data.user_role == 'company_user') {
        userData.company_id = data?.company_id
        userData.department = data?.department
        userData.id = data?._id
    } if (data.user_role == 'school_user') {
        userData.id = data?._id
    }
    return userData
}