import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Organization } from '../../Services/Organization';
import { buildUser } from '../../helpers/user-builder';
import ParSignupStepOne from './ParSignupStepOne';
import ParSignupStepThree from './ParSignupStepThree';
import ParSignupStepTwo from './ParSignupStepTwo';
import ParSignupStepFour from './ParSignupStepFour';
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { useSearchParams } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function ParentRegistration(props) {
  const data = props.location.state ? props.location.state : null
  const [step, setstep] = useState(1);

  const search = useHistory()
  // const student_id = useSearchParams()

  const queryParameters = new URLSearchParams(window.location.search)
  const student_id = queryParameters.get("student_id")
  const guardian_email = queryParameters.get("email")
  // const name = queryParameters.get("name")
  // let userData=null
  // if (data) {
  //   userData = buildUser(data)
  // }
  const [schlFormData, setSchlFormData] = useState({
    student_id: "",
    first_name: "",
    last_name: "",
    email: guardian_email ? guardian_email : "",
    phone: "",
    address: "",
    guardian_name: "",
    guardian_email: guardian_email ? guardian_email : "",
    timezone:'',
    password: "",
    name_visible: false,
    number_visible: false,
    allow_live_session: false,
    become_facilitator: false,
    facilitator_selection: '',
    facilitator_email: "",
    parental_consent: false,
    accept_privacy_policy: false,
    otp_verified: false,
    confirmpassword: '',
    source: data ? data.source : null
  })


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setSchlFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  function handleImage(newValue) {
    setSchlFormData(prevState => ({
      ...prevState,
      ['logo']: newValue
    }));
  }

  function handleSchool(name, newValue) {
    setSchlFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  }
  const handleContactBy = input => e => {
    const { value, checked } = e.target;
    const { contact_by } = schlFormData;
    if (checked) {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: [...contact_by, value],
      }));

    } else {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: contact_by.filter((e) => e !== value),
      }));

    }
  }

  const [value, setValue] = useState('');
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const sendOtp = () => {
    if (schlFormData.guardian_email !== '') {
      School.sendEmail({ email: guardian_email, in_register: true }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            // setMessage(data.message)

          }


        },
        (error) => {
          // setError(error.toString())
          // NotificationManager.success(error.toString(), 'Error!', 2000);
        }
      )
      // nextStep();

    } else {
      NotificationManager.success('Email is required', 'Error!', 2000);
    }
  }
  switch (step) {
    case 1:
      return (
        <>
          <ParSignupStepOne nextStep={nextStep} handleImage={handleImage} handleFormData={handleInputData} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 2:
      return (
        <>
          <ParSignupStepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 3:
      return (
        <>
          <ParSignupStepThree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 4:
      return (
        <>
          <ParSignupStepFour values={schlFormData} prevStep={prevStep} handleFormData={handleInputData} handleSchool={handleSchool} />
        </>
      )
    // never forget the default case, otherwise VS code would be mad!
    default:
    // do nothing
  }
}

export default ParentRegistration
