import React, { useState, useEffect } from 'react'
import {
    Container,
    Nav,
    Navbar,
    Col,
    Row,
    Button,
    Form,
    FormControl,
    InputGroup,
    Stack,
    Modal
} from "react-bootstrap";
import { TextField } from '@material-ui/core';
import { NavLink, Link, useHistory } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import TimePicker from 'react-time-picker';
import CalendarUI from '../CalenderUI';
function Session1({ index, element, grade, subject, error1, handleChange, selectedDay, addStartTime, addEndTime, removeFields, handleChangeSetData, startTime, endTime, scheduleDay, scheduleDate }) {
    return (<Row key={`slot-${index}`}>
        <Col md={12} className="mb-3 text-end">
            {
                index ?
                    <a className="btn btn-dark mb-3" onClick={() => removeFields(index)}><i className="fa fa-close"></i></a>
                    : null
            }
        </Col>
        <Col md={12} className="mb-3">
            <CalendarUI onChange={(value, event) => selectedDay(index, value)} className='' />
            {/* <DatePicker getSelectedDay={e => selectedDay(index, e)}
                endDate={100}
                selectDate={new Date()}
                name={`date${index}`}
                id={`date${index}`}
                className={'date1'}
                labelFormat={"MMMM"}
                color={"#287AEB"}
                labelColor={'red'}
                marked={[
                    {
                        date: new Date(2021, 9, 3),
                        marked: true,
                        style: {
                            color: "#ff0000",
                            padding: "2px",
                            fontSize: 12,
                        },
                        text: "1x",
                    },
                    {
                        date: new Date(2021, 9, 4),
                        marked: true,
                        text: "5x"
                    },
                ]}
            />
            <span className={`err date${index}`}>{error1?.[index]?.date}</span> */}
        </Col>
        {/* {element?.date && <Col md={12} className="mb-3 text-center">Date: {element?.date}</Col>} */}
        <Col md={6}>
            <Form.Group className="mb-3">
                <Form.Label className='p-1'>Subject</Form.Label>
                <Form.Select name="subject" onChange={e => handleChange(index, e)}>
                    <option value="">Subject Selected*</option>
                    {subject && subject?.map((content, index) => (
                        <option key={index} value={content._id}>{content.title}</option>
                    ))}
                </Form.Select>
                <span className={`err subject${index}`}>{error1?.[index]?.subject}</span>
            </Form.Group>
        </Col>
        <Col md={6}>
            <Form.Group className="mb-3">
                <Form.Label className='p-1'> Grades </Form.Label>
                <Form.Select name="grade_id" onChange={e => handleChange(index, e)}>
                    <option value="">Select Grade Preference**</option>
                    {grade.map(grd => <option value={grd._id}>{grd.title}</option>)}
                    {/* <option value="2">Session 2</option>
                    <option value="3">Session 3</option>
                    <option value="4">Session 4</option>
                    <option value="5">Session 5</option>
                    <option value="6">Session 6</option> */}
                </Form.Select>
                <span className={`err grade_id${index}`}>{error1?.[index]?.grade_id}</span>
            </Form.Group>
        </Col>
        {/* <Col md={12}>
            <Form.Group className="mb-3">
                <Form.Select name="session" onChange={e => handleChange(index, e)}>
                    <option value="">Type of session*</option>
                    <option value="1">Session 1</option>
                    <option value="2">Session 2</option>
                    <option value="3">Session 3</option>
                    <option value="4">Session 4</option>
                    <option value="5">Session 5</option>
                    <option value="6">Session 6</option>
                </Form.Select>
                <span className={`err session${index}`}>{error1?.[index]?.session}</span>
            </Form.Group>
        </Col> */}
        <Col md={6}>
            <Form.Group className="mb-3">
                <Form.Label className='p-1'> Start Time </Form.Label>
                <TimePicker disableClock={true} clearIcon={''} format='hh:mm aa' label="Start Time" name="start_time" onChange={e => addStartTime(index, e)} value={startTime[index]} renderInput={(params) => <TextField {...params} />} />
                <span className={`err start_time${index}`}>{error1?.[index]?.start_time}</span>
            </Form.Group>
        </Col>
        <Col md={6}>
            <Form.Group className="mb-3">
                <Form.Label className='p-1'> End Time </Form.Label>
                <TimePicker disableClock={true} clearIcon={''} format='hh:mm aa' name="end_time" label="End Time" onChange={e => addEndTime(index, e)} value={endTime[index]} renderInput={(params) => <TextField {...params} />} />
                <span className={`err end_time${index}`}>{error1?.[index]?.end_time}</span>
            </Form.Group>
        </Col>
        <Col md={12}>
            <Form.Group className="mb-3">
                <Form.Label className='p-1'> Session Type </Form.Label>
                <Form.Select name="type_of_tutoring_session" onChange={e => handleChange(index, e)}>
                    <option value="">Type of tutoring session*</option>
                    <option value="daily session">Daily session</option>
                    <option value="weekly session">Weekly session</option>
                    <option value="monthly session">Monthly session</option>
                </Form.Select>
                <span className={`err type_of_tutoring_session${index}`}>{error1?.[index]?.type_of_tutoring_session}</span>
            </Form.Group>
        </Col>
        <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check className="text-grey" type="radio" name={`recurring_option${index}`} id="flexRadioDefault1" onChange={e => handleChangeSetData(index, 'recurring_option', scheduleDay[index])} label={`Apply the schedule to all ${scheduleDay[index]}`} defaultValue={scheduleDay[index]} />
                <span className={`err recurring_option${index}`}>{error1?.[index]?.recurring_option}</span>
            </Form.Group>
        </Col>
        <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                <Form.Check className="text-grey" type="radio" name={`recurring_option${index}`} id="flexRadioDefault2" onChange={e => handleChangeSetData(index, 'recurring_option', new Date(scheduleDate[index]).getDay())} label={`Apply the schedule to ${new Date(scheduleDate[index]).getDate()} of Month`} defaultValue={scheduleDate[index]} />
            </Form.Group>
        </Col>

    </Row>);
}
export default Session1;