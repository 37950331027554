import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolInternalSideMenu from "./SchoolInternalSideMenu";
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';
import config from "./../../config";
import { getResourceUrl } from '../../Services/CommanFunctions';

function SchoolInternalDashboard() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [admin, setAdmin] = useState([]);
  const [error, setError] = useState([]);
  const handleChange = (e, id) => {
    School.post({ user_id: id, status: e.target.value }, 'users/update_status').then(
      (data) => {
        NotificationManager.success(data.message, 'success!', 2000);
      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }
  useEffect(() => {
    School.post({}, 'users/get_all_user').then(
      (data) => setAdmin(data),
      (error) => setError(error.toString())
    )

  }, [])
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolInternalSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-4">
                  <div className='d-flex align-items-center'>
                    <div className="school-logo-small">
                      <img src="images/school-logo3.png" alt="" />
                    </div>
                    <h3 className="text-muted mb-0">  Mather High School</h3></div>


                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>

                      <li>
                        <a href="#">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a>
                      </li>
                      <li>
                        <a href="#">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a>
                      </li>
                      <li onClick={handleShow}>
                        <a className="btn btn-primary" href="#">Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category" style={{ paddingLeft: 0, }}>
                    <ul >
                      <li>
                        <Link to="/school-dashboard">Students</Link>
                      </li>
                      <li >
                        <Link to="/school-tutor">Tutors</Link>
                      </li>
                      <li>
                        <Link to="/school-facilitator">Facilitators</Link>
                      </li>
                      <li>
                        <Link to="/school-facilitator">Parents/Guardians</Link>
                      </li>
                      <li className="active">
                        <Link to="/school-facilitator">School Admin</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Department</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Email</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admin && admin?.data && admin?.data?.length > 0 && admin?.data?.map((content, index) => (
                          <tr key={index}>
                            <td>
                              {(content?.display_logo == 'yes' && content?.propic) ? (<img src={getResourceUrl(content?.propic)} alt="" width="44" className="rounded me-2" />) : null}
                              {content?.name}
                            </td>
                            <td>Student Experience</td>
                            <td>{content?.phone}</td>
                            <td>{content?.email}</td>
                            <td>
                              <Form.Group style={{ minWidth: 120 }}>

                                <Form.Select className="declineSlct" defaultValue={content?.status} onChange={(e) => handleChange(e, content._id)}>
                                  <option value="Active" >Active</option>
                                  <option value="Inactive">Inactive</option>
                                </Form.Select>
                              </Form.Group>


                            </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src="images/no_profile_img.png" alt="" />
                </div>
                <div className="user-short-name">
                  <h5>Balaji</h5>
                  <p>Status: Admin</p>
                </div>
                <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/avatar.jpg" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">View Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Edit Account</Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>Sarah Doe</h5>

                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="btn btn-primary solid btn-sm no-shadow d-block">Activate Account</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>



                  <Row>
                    <Col md={6}>
                      <h4>Contact:</h4>
                      <p>
                        312 662 8517
                      </p>
                    </Col>
                    <Col md={6}>
                      <h4>Department:</h4>
                      <p>Accountant</p>
                    </Col>
                  </Row>




                  <h4>Email:</h4>
                  <p>jane@mather.edu</p>

                </div>
                <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow same-width" variant="primary">Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill same-width" variant="secondary">Decline</Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton className='text-center'>
          <Modal.Title >Add School Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="text" placeholder="School Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="text" placeholder="School Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Zip Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value=" " placeholder="Point of Contact First Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="" placeholder="Point of Contact Last Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="  " placeholder="Contact Number" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="" placeholder="Email" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="email" value="" placeholder="Department" />
                </Form.Group>

                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary">Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolInternalDashboard
