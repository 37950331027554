import { useEffect, useState } from "react";
import { Card, CardGroup, Col } from "react-bootstrap";
import { FecthData } from "../../Services/FetchData";
import config from "../../config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getResourceUrl } from "../../Services/CommanFunctions";

export default function Resource({userId}){
  const user = JSON.parse(localStorage.getItem('user'))
  const role=user?.role
  const initialCat=role=='student' || role=='tutor'? 'featured':''
  const [resource, setResource] = useState([])
  const [searchCat, setSearchCat] = useState(initialCat)
  const [selectedTab, setSelectedTab] = useState(1);

  const onChangeTabs = (searchCat, tabId) => {
    setSearchCat(searchCat)
    setSelectedTab(tabId)
  }

  useEffect(() => {
    const commonQ=`?category=${searchCat}&role=${role}`
    const endPoint='users/get_resources'
    const studQ=role=='student'?`&student_id=${user?._id}&grade_id=${user?.student?.grade_id?._id}`:''
    const tutorQ=role=='tutor' && searchCat=='assignment'?`&tutor_id=${user?.tutor?._id}`:''
    var queryParams=''
    if(role=='student'){
      queryParams=commonQ+studQ
    }else if(role=='tutor'){
      queryParams=commonQ+tutorQ
    }else{
      queryParams=commonQ
    }
    const url=endPoint+queryParams
    FecthData.getData(url).then(
      (data) => setResource(data?.data)
    )
  }, [searchCat])
    return <><Col md={12}>
    <div className="video-category">
      <ul>
        {role=='student' || role=='tutor'?<li className={selectedTab == 6 ? "active" : ''} onClick={() => onChangeTabs('assignment', 6)}>
          <a href="javascript:void(0)">Assignment</a>
        </li>:null}
        <li className={selectedTab == 1 ? "active" : ''} onClick={() => onChangeTabs('featured', 1)}>
          <a href="javascript:void(0)">Featured</a>
        </li>
        <li className={selectedTab == 3 ? "active" : ''} onClick={() => onChangeTabs('Archived Classed', 3)}>
          <a href="javascript:void(0)">Archived Classed</a>
        </li>
        <li className={selectedTab == 4 ? "active" : ''} onClick={() => onChangeTabs('library', 4)}>
          <a href="javascript:void(0)">Library</a>
        </li>
        <li className={selectedTab == 5 ? "active" : ''} onClick={() => onChangeTabs('favorites', 5)}>
          <a href="javascript:void(0)">Favorites</a>
        </li>
        <li className={selectedTab == 2 ? "active" : ''} onClick={() => onChangeTabs('E-Books', 2)}>
          <a href="javascript:void(0)">E-Learning</a>
        </li>
      </ul>
    </div>
  </Col>
  <Col md={12}>
    <h6 className="text-muted mt-2">Featured Videos</h6>
  </Col>
  
  {resource?.map(res =>
    <Card className="col-md-3 d-flex align-items-center text-center m-2 shadow">
    <Card.Img variant="top" src={res?.image ? getResourceUrl(res?.image)  : "images/video1.jpg"} alt=" " className="mt-1 rounded" height={150} width={150} />
    <Card.Body>
      <h5 className="text-muted">{res?.title}</h5>
      {/* <Card.Text>`
       {res?.description}
      </Card.Text> */}
    </Card.Body>
    <a  href={res.file ? getResourceUrl(res.file) : 'javascript:void(0)'}
        target={res.file ? "_blank" : null} className="mb-2 btn-primary">
        Download Pdf
      </a>
  </Card>
    // <Col md={3}  >
    //           <div className="school-box">
    //               <div className="school-box-logo">
    //               <img src={res?.image ? getResourceUrl(res?.image)  : "images/video1.jpg"} alt=" " />
    //               </div>
    //               <h4 className="mb-1">{res?.title}</h4>
    //             <a  href={res.file ? getResourceUrl(res.file) : 'javascript:void(0)'}
    //     target={res.file ? "_blank" : null} className="btn-primary watch-btn">
    //     download
    //   </a>
    //           </div>
    //         </Col>
  // <Col md={3}>
  //   <div className="video-box">
  //     <img src={res?.image ? res?.image : "images/video1.jpg"} alt=" " />
  //     <div className="video-box-text">
  //       <h4>{res?.title}</h4>
  //       <p>1:30 min</p>
  //     </div>
  //     <a href={res.file ? res.file : 'javascript:void(0)'}
  //       target={res.file ? "_blank" : null} className="watch-btn">
  //       <img src="images/play-icon.png" alt="" />
  //     </a>
  //   </div>
  // </Col>
)}
  {/* <Col md={3}>
    <div className="video-box">
      <img src="images/video2.jpg" alt="" />
      <div className="video-box-text">
        <h4>Functions & Graphing</h4>
        <p>1:30 min</p>
      </div>
      <a href="javascript:void(0)" className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col>
  <Col md={3}>
    <div className="video-box">
      <img src="images/video3.jpg" alt="" />
      <div className="video-box-text">
        <h4>Functions & Graphing</h4>
        <p>1:30 min</p>
      </div>
      <a href="javascript:void(0)" className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col>
  <Col md={3}>
    <div className="video-box">
      <img src="images/video4.jpg" alt="" />
      <div className="video-box-text">
        <h4>Functions & Graphing</h4>
        <p>1:30 min</p>
      </div>
      <a href="javascript:void(0)" className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col> */}
  {/* <Col md={12}>
    <h6 className="text-muted mt-2">Most Viewed</h6>
  </Col>
  {resource?.map(res => <Col md={3}>
    <div className="video-box">
      <img src={res?.image ? res?.image : "images/video1.jpg"} alt="" />
      <div className="video-box-text">
        <h4>{res?.title}</h4>
        <p>1:30 min</p>
      </div>
      <a href={res.file ? res.file : 'javascript:void(0)'}
        target={res.file ? "_blank" : null} className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col>)} */}
  {/* <Col md={3}>
    <div className="video-box">
      <img src="images/video2.jpg" alt="" />
      <div className="video-box-text">
        <h4>Functions & Graphing</h4>
        <p>1:30 min</p>
      </div>
      <a href="javascript:void(0)" className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col>
  <Col md={3}>
    <div className="video-box">
      <img src="images/video3.jpg" alt="" />
      <div className="video-box-text">
        <h4>Functions & Graphing</h4>
        <p>1:30 min</p>
      </div>
      <a href="javascript:void(0)" className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col>
  <Col md={3}>
    <div className="video-box">
      <img src="images/video4.jpg" alt="" />
      <div className="video-box-text">
        <h4>Functions & Graphing</h4>
        <p>1:30 min</p>
      </div>
      <a href="javascript:void(0)" className="watch-btn">
        <img src="images/play-icon.png" alt="" />
      </a>
    </div>
  </Col> */}</>
}