import _ from "lodash"
import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { FecthData } from "../../Services/FetchData"
import { formatDate, timeFormate } from "../../helpers/utils"
import Calendar from "react-calendar"

function SessionCalendar() {
  const selectedDay = val => {
    var da=new Date()
    console.log(new Date(val).toJSON());
    const date =formatDate( new Date(val))
    selectDate(date)
  };
  const [selectedDate, selectDate] = useState(formatDate(new Date()))
  const [sessions, setSessions] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))
  const getStudentSessions = () => {
    FecthData.postJsonData({ student_id: user?.student?._id, date: selectedDate }, 'student/get_student_session_by_student_id').then(
      (data) => setSessions(data?.data)
    )
  }
  useEffect(() => {
      getStudentSessions()
  }, [selectedDate])
  const studentSessions = _.filter(sessions, session => {
    if (session?.tutor_session_id) {
      return session
    }
  })
  return <div className="upcom-class">
    {/* <h5 className="text-muted">Calendar</h5> */}
    {/* <DatePicker getSelectedDay={selectedDay}
      endDate={100}
      selectDate={new Date()}
      labelFormat={"MMMM"}
      color={"#287AEB"}
      marked={[
        {
          date: new Date(),
          marked: true,
          style: {
            color: "#287AEB",
            padding: "2px",
            fontSize: 12,
          },
          text: "1x",
        },
        {
          date: new Date(2023, 17, 11),
          marked: true,
          text: "5x"
        },
      ]}
    /> */}
    <Calendar onChange={selectedDay} minDate={new Date()} />
    {sessions.length > 0 ? <div className="upcoming-class-schedule">
      <ul>
        {sessions.map(stdSession => <li className="eight-am mb-1">
          <div className="left">
            <p>{timeFormate(stdSession?.tutor_session_id?.start_time)} </p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>{stdSession?.tutor_session_id?.subject?.title} with {stdSession?.tutor_session_id?.tutor_id?.first_name}.</h5>
              <p>{timeFormate(stdSession?.tutor_session_id?.start_time)}-{timeFormate(stdSession?.tutor_session_id?.end_time)}</p>
            </div>
            {/* <div className="three-dot">
              <img src="images/three-dot.png" alt="" width="20" />
            </div> */}
            <Dropdown className="watch-btn-option">
              <Dropdown.Toggle>
                <img src="images/three-dot.png" alt="" width="20" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="javascript:void(0)">View</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item href="#">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>)}
        {/* <li className="nine-am">
          <div className="left">
            <p>9 AM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Algebra with Sarah D.</h5>
              <p>9:00 am-9:30 am</p>
            </div>
            <Dropdown className="watch-btn-option">
              <Dropdown.Toggle>
                <img src="images/three-dot.png" alt="" width="20" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">View</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item href="#">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
        <li className="ten-am mt-4">
          <div className="left">
            <p>10 AM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Geography with John M.</h5>
              <p>8:00 am-9:00 am</p>
            </div>
            <Dropdown className="watch-btn-option">
              <Dropdown.Toggle>
                <img src="images/three-dot.png" alt="" width="20" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">View</Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item href="#">Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>
        <li className="eleven-am no-class">
          <div className="left">
            <p>11 AM</p>
          </div>
          <div className="right">
            
          </div>
        </li>
        <li className="twelve-pm">
          <div className="left">
            <p>12 AM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Physics with David G.</h5>
              <p>12:00 pm-1:00 pm</p>
            </div>
            <div className="three-dot">
              <img src="images/three-dot.png" alt="" width="20" />
            </div>
          </div>
        </li>
        <li className="one-pm">
          <div className="left">
            <p>1 PM</p>
          </div>
          <div className="right">
            <div className="classname">
              <h5>Geometry with Jane D.</h5>
              <p>1:00 pm-1:30 pm</p>
            </div>
            <div className="three-dot">
              <img src="images/three-dot.png" alt="" width="20" />
            </div>
          </div>
        </li>
        <li className="no-class">
          <div className="left">
            <p>2 PM</p>
          </div>
          <div className="right">

          </div>
        </li>
        <li className="no-class">
          <div className="left">
            <p>3 PM</p>
          </div>
          <div className="right">

          </div>
        </li>
        <li className="no-class">
          <div className="left">
            <p>4 PM</p>
          </div>
          <div className="right">

          </div>
        </li> */}
      </ul>
    </div> : <p className="text-center m-1" style={{ fontSize: '20px' }}>No upcoming sessions found!</p>}
  </div>
}
export default SessionCalendar