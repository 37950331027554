import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import SessionCalendar from './SessionCalendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FecthData } from '../../Services/FetchData';
import config from '../../config';
import Resource from '../common/Resource';
import FieldErrors from '../../Services/FieldErrors';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function Resources() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [resource, setResource] = useState([])
  const [searchCat, setSearchCat] = useState('assignment')
  const [selectedTab, setSelectedTab] = useState(1);
  const history = useHistory()
  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [error, setError] = useState()
  const [studTutors, setStudTutors] = useState([]);
  const [formData, setFormData] = useState({
    user_id: user?._id,
    logo: null,
    title: '',
    grade_id: user?.student?.grade_id?._id,
    subject_id: null,
    description: '',
    category: 'assignment',
    audience: ['tutor', 'student'],
    link: '',
    file: null,
    tutor_id: null
  })
  const submitForm = () => {
    const payload = new FormData()
    Object.entries(formData).map(([key, value]) => {
      payload.append(key, value)
    })
    Admin.postFormData(payload, 'users/create_resources').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data?.message, 'Success!', 2000);
        } else {
          setError(data.errors.details)
          NotificationManager.error(data?.message, 'error!', 2000);
        }
      }
    )
  }
  const onChangeTabs = (searchCat, tabId) => {
    setSearchCat(searchCat)
    setSelectedTab(tabId)
  }

  const selectedDay = val => {
    console.log(val);
  };
  const logout = (url) => {
    localStorage.clear()
    history.push(url)
  }
  const getStudentTutors = () => {
    FecthData.getData('student/get_student_tutors/' + user?.student?._id).then(
      (data) => setStudTutors(data?.data)
    )
  }
  useEffect(() => {
    FecthData.getData('users/get_resources?category=' + searchCat + '&student_id=' + user._id + '&grade_id=' + user.student.grade_id._id).then(
      (data) => setResource(data?.data)
    )
  }, [searchCat])
  useEffect(() => {
    FecthData.getData('streem_and_subject/get_all_subject').then(
      (data) => {
        if (data.error === 0) {
          setSubject(data?.data)
          setFormData({ ...formData, subject_id: data?.data[0]?._id })
        }
      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
    getStudentTutors()
  }, [])
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              {/* <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                <div className="mt-4">
                  <h6 className="text-muted mt-3">Recently Viewed</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box1.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-video-btn">Watch The Video</a>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box2.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Expressions & Equations</h4>
                    <p>1:00 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-video-btn">Watch The Video</a>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box3.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Statistics & Probability</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-video-btn">Watch The Video</a>
                </div>
              </Col> */}
              <Resource />
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={getResourceUrl(user?.student?.gender_avatar?.icon_image)} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.student_profile?.school_name}</h5>
                  <p>Academic Year {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout('/')}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {/* <SessionCalendar /> */}
            <div className="card">
              <div className="school-side-details">
                <h5 className="text-muted">Upload Resources</h5>
                <Form>
                  <Row>
                    <Form.Group className="form-group my-3" as={Col} md="12">
                      <div className="upload-resource">
                        <img src={formData.logo ? URL.createObjectURL(formData.logo) : "images/camera-icon.png"} alt="" width="78" />
                        <input type="file" onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
                      </div>
                      <FieldErrors errors={error} field="logo" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}>
                      </Form.Control>
                      <FieldErrors errors={error} field="title" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey" defaultValue={formData?.tutor_id} onChange={(e) => setFormData({ ...formData, tutor_id: e.target.value })}>
                        <option >Select Tutor</option>
                        {studTutors.map(item => <>{item?._id != null ? <option value={item?._id}>{item?.document?.tutor_details[0]?.first_name}</option> : null}</>)}
                      </Form.Select>
                      <FieldErrors errors={error} field="grade" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey" onChange={(e) => setFormData({ ...formData, subject_id: e.target.value })}>
                        <option disabled selected>Select Subject</option>
                        {subject?.map(sub => <option value={sub._id}>{sub.title}</option>)}
                      </Form.Select>
                      <FieldErrors errors={error} field="subject" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Select placeholder='Select' className="bg-grey" onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                        <option disabled selected>Select Category</option>
                        {/* <option value="E-Books">E-Book</option>
                        <option value="Teaching Material">Teaching Material</option>
                        <option value="Archived Classed">Archived Classed</option> */}
                        <option value="assignment">Assignment</option>
                      </Form.Select>
                      <FieldErrors errors={error} field="category" />
                    </Form.Group>
                    {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control as="select" className="bg-grey" multiple defaultValue={formData.audience} onChange={(e) => {
                        var audience = [].slice.call(e.target.selectedOptions).map(item => item.value)
                        setFormData({ ...formData, audience: audience })
                      }} >
                        <option disabled>Select Audience</option>
                        <option value='global'>Global</option>
                        <option value="student">Student</option>
                        <option value="tutor">Tutor</option>
                        <option value="facilitator">Facilitator</option>
                        <option value="parent">Parent</option>
                      </Form.Control>
                      <FieldErrors errors={error} field="audience" />
                    </Form.Group> */}
                    {!formData?.file && <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" type="text" placeholder="Add link" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
                      <FieldErrors errors={error} field="link" />
                    </Form.Group>}
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" as="textarea" placeholder="Add Description" rows="4" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                      <FieldErrors errors={error} field="description" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <div className="attach-file-res mt-3">
                        <img src="images/attach-file.png" alt="" width="100" />
                        <br />
                        <p>{formData?.file?.name}</p>
                        <input type="file" onChange={(e) => setFormData({ ...formData, file: e.target.files[0] })} />
                      </div>
                      <FieldErrors errors={error} field="file" />
                    </Form.Group>
                    <Col md="12" className="text-center mt-2">
                      <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={submitForm}>Upload</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            {/* <div className="upcom-class">
              <h5 className="text-muted">Calendar</h5>
              <DatePicker getSelectedDay={selectedDay}
                endDate={100}
                selectDate={new Date("2022-02-08")}
                labelFormat={"MMMM"}
                color={"#287AEB"}
              />
              <div className="upcoming-class-schedule">
                <ul>
                  <li className="eight-am">
                    <div className="left">
                      <p>8 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Chemistry with Jane D.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="nine-am">
                    <div className="left">
                      <p>9 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Algebra with Sarah D.</h5>
                        <p>9:00 am-9:30 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="ten-am mt-4">
                    <div className="left">
                      <p>10 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geography with John M.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="eleven-am no-class">
                    <div className="left">
                      <p>11 AM</p>
                    </div>
                    <div className="right">
                      
                    </div>
                  </li>
                  <li className="twelve-pm">
                    <div className="left">
                      <p>12 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Physics with David G.</h5>
                        <p>12:00 pm-1:00 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="one-pm">
                    <div className="left">
                      <p>1 PM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geometry with Jane D.</h5>
                        <p>1:00 pm-1:30 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>2 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>3 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>4 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default Resources
