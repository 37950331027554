import config from "../config"
import { history } from "../helpers/history"
import { FecthData } from "./FetchData"

export const deleteUser = (user_id, deleted_by, redirect_url) => {
    return FecthData.deleteAPI('users/delete_user/' + user_id + '?role=' + deleted_by).then(
        (data) => {
            return data
            // history.goBack()
            // history.go(redirect_url)
        }
    ).catch(e => console(e))
}

export const getResourceUrl = (url) => {
    if(url){
        if (url.includes('https://'))
            return url
        return config.assetUrl + url
    }
    
}