import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import config from "./../../config";
import { NavLink, Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import { Next } from 'react-bootstrap/esm/PageItem';
import ActionButton from '../ActionButton';
import { FecthData } from '../../Services/FetchData';
import { getResourceUrl } from '../../Services/CommanFunctions';


function SignupStepFive({ nextStep, handleFormData, prevStep, values, addYourSubject }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const [studentUser, setStudentUser] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [filterParam, setFilterParam] = useState(["All"]);
  const [searchParam] = useState(["title", "subjects"]);
  const [q, setQ] = useState("");
  const [message, setMessage] = useState(null);
  let history = useHistory();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const selectSubject = e => {
    const { value, checked } = e.target;
    // if (checked) {
    setSelectedSubject(value)
    // } else {
    //   setSelectedSubject((prevState) =>
    //     prevState.filter((fruit) => fruit !== value)
    //   );
    // }
    addYourSubject(e)
  }
  const submitFormData = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('subjects', values.subjects.toString());
    formData.append('student_id', values.student_id);
    Student.post(formData, 'student/student_add_subject').then(
      (data) => {
        if (data.error === 0) {
          NotificationManager.success(data.message, 'success!', 2000);
          setMessage(data.message)
          nextStep()
          //  localStorage.setItem('student_subject', JSON.stringify(data.data))
          //  history.push('/school');
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }


  function search(subject) {
    return subject.filter((item) => {

      /*
      // in here we check if our region is equal to our c state
      // if it's equal to then only return the items that match
      // if not return All the countries
      */

      return searchParam.some((newItem) => {

        // if(q !==''){
        return item.subjects.filter((x) => {

          return Object.keys(x).some((k) => {
            return (x.title.toLowerCase().includes(q.toLowerCase()) > -1)
          })
        })
        // x[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        // }else{
        //   console.log('this is item ',item.title)
        //   return (
        //          item[newItem]
        //              .toString()
        //              .toLowerCase()
        //              .indexOf(item.title.toLowerCase()) > -1
        //      );
        //  }

        //  return (
        //      item[newItem]
        //          .toString()
        //          .toLowerCase()
        //          .indexOf(q.toLowerCase()) > -1
        //  );
      });
      // if (item.title == filterParam) {
      //     return searchParam.some((newItem) => {
      //       return (
      //         item[newItem]
      //             .toString()
      //             .toLowerCase()
      //             .indexOf(q.toLowerCase()) > -1
      //                  );
      //              });
      //          } else if (filterParam == "All") {
      //              return searchParam.some((newItem) => {

      //              // if(q !==''){
      //                 return item.subjects.filter( (x) => Object.keys(x).some(k => x.title.toLowerCase().includes(q.toLowerCase()) > -1) )
      //                // x[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      //               // }else{
      //               //   console.log('this is item ',item.title)
      //               //   return (
      //               //          item[newItem]
      //               //              .toString()
      //               //              .toLowerCase()
      //               //              .indexOf(item.title.toLowerCase()) > -1
      //               //      );
      //               //  }

      //                 //  return (
      //                 //      item[newItem]
      //                 //          .toString()
      //                 //          .toLowerCase()
      //                 //          .indexOf(q.toLowerCase()) > -1
      //                 //  );
      //              });
      //          }
    });
  }

  useEffect(() => {
    const studentUserz = localStorage.getItem("student");
    if (studentUserz) {
      const foundUser = JSON.parse(studentUserz);
      setStudentUser(foundUser);
    }

    FecthData.getData('streem_and_subject/get_all_streem_wise_subject').then(
      (data) => {
        if (data.error === 0) {
          setSubject(data.data)
        }

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }, []);

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Completion</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <div className="user-are">
                      <div className="user-avatar">
                        {studentUser && studentUser?.gender_avatar && <img src={studentUser?.gender_avatar?.icon_image ? getResourceUrl(studentUser?.gender_avatar?.icon_image) : "images/avatar4.png"} alt="" />}
                        {/* {studentUser && studentUser?.gender_avatar == 'female' && <img src="images/avatar2.png" alt="" />} */}
                      </div>
                      <div className="user-text">
                        <h4>{studentUser?.first_name + ' ' + studentUser?.last_name} | ID: {studentUser?.student_code} </h4>
                        <h6>Academic Year  {studentUser?.academic_year}</h6>
                      </div>
                    </div>
                    <h4>What subject would you like to learn?</h4>
                    <p>Get the assistance you need by selecting the subjects you need help with.</p>
                    <div className="signup-step" style={{ marginTop: 240, }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Form.Group className="form-group mb-2" style={{ zIndex: 2 }}>
                        {/* <Form.Control type="text" placeholder="Search" /> */}
                        {/* <Autocomplete className="search"
                          items={[
                            { label: 'Algebra' },
                            { label: 'Geometry' },
                            { label: 'Calculus' },
                            { label: 'Biology' },
                            { label: 'Chemistry' },
                            { label: 'Physics' },
                          ]}
                          shouldItemRender={(item, value
                          ) => item.label.toLowerCase()
                            .indexOf(value.toLowerCase()) > -1}
                          getItemValue={item => item.label}
                          renderItem={(item, isHighlighted) =>
                            <div style={{
                              background: isHighlighted ?
                                '#eee' : 'white',
                              padding: '5px 10px',
                            }}
                              key={item.id}>
                              {item.label}
                            </div>
                          }
                          value={value}
                          onChange={e => setValue(e.target.value)}
                          onSelect={(val) => setValue(val)}
                          inputProps={{
                            style: {
                              width: '100%',
                              background: '#fff',
                              boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                              width: '100%',
                              color: '#000',
                              borderRadius: 10,
                              minHeight: 52,
                              border: '1px solid #E9ECF4',
                              fontSize: 14,
                              paddingLeft: 15,
                              paddingRight: 15,
                            },
                            placeholder: 'Search'
                          }}
                          wrapperStyle={{
                            width: '100%',
                          }}
                        />
                        <Button variant="primary" type="submit" className="search-btn">
                          <img src="images/search-icon.png" alt="" width="22" />
                        </Button> */}
                      </Form.Group>
                      {/* <div className="search-wrapper">
                            <label htmlhtmlFor="search-form">
                                <input
                                    type="search"
                                    name="search-form"
                                    id="search-form"
                                    className="search-input"
                                    placeholder="Search for..."
                                    onChange={(e) =>  setQ(e.target.value)}
                                />
                            </label>
                        </div> */}
                      <div className="subject-name ">
                        {subject && subject?.length > 0 && <>
                          {search(subject).map((content, index) => (<>
                            {content?.subjects.length > 0 && <><h6 key={index} className="text-muted">{content?.title.toUpperCase()}</h6>
                              <ul className='slctGrade'>
                                {content?.subjects?.map((subjectz, i) => (<li key={i} className={(selectedSubject == subjectz._id || values.subjects.includes(subjectz._id)) ? 'selected m-2' : 'm-2'} onClick={() => selectSubject({ target: { value: subjectz._id } })}>
                                  <input name="subject" type="radio" onChange={(e) => selectSubject(e)} checked={selectedSubject == subjectz._id} value={subjectz._id} />
                                  <div className="sub-img">
                                    <img src={getResourceUrl(subjectz?.icon_image)} alt="" />
                                  </div>
                                  <h6 className="blue">{subjectz?.title}</h6>
                                </li>))}
                              </ul>
                            </>}
                          </>))}
                        </>}
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={values.subjects} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default SignupStepFive
