import { Button, Modal } from "react-bootstrap";

export default function ToastModal({show,onHide,modalTitle,modalBody,centered,bg,showFooter}){
    return <Modal
    show={show}
    onHide={onHide}
    size="sm"
    centered={centered}
  >
    <Modal.Header bg={bg} closeButton>
      <Modal.Title >
        {modalTitle}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body >
      <div className={"alert alert-"+bg}>{modalBody}</div>
      {/* <p>
        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
        consectetur ac, vestibulum at eros.
      </p> */}
    </Modal.Body>
    {showFooter? <Modal.Footer>
      <Button onClick={onHide} >Ok</Button>
    </Modal.Footer>:null}
  </Modal>
}