import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Organization } from '../../Services/Organization';
import OrgSignupStepOne from './OrgSignupStepOne';
import OrgSignupStepTwo from './OrgSignupStepTwo';
import OrgSignupStepThree from './OrgSignupStepThree';
import OrgSignupStepFour from './OrgSignupStepFour';
import { buildUser } from '../../helpers/user-builder';

function OrgRegistration(props) {
  const data = props.location.state ? props.location.state : null
  const [step, setstep] = useState(1);
  let userData = buildUser(data)
  // if (data) {
  //   userData = buildUser(data)
  // }
  const [formData, setFormData] = useState({
    ...userData,
    company: "",
    company_id: "",
    company_name: "",
    company_ein: "",
    company_address: "",
    company_city: "",
    company_state: "",
    company_zip: "",
    logo: "",
    timezone:'',
    // logo_url: "",
    display_logo: "yes",
    // first_name: "",
    // last_name: "",
    // email: "",
    department: "",
    contact_number: "",
    contact_by: [],
    accept_privacy_policy: false


  })


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  function handleImage(newValue) {
    setFormData(prevState => ({
      ...prevState,
      ['logo']: newValue
    }));
  }

  function handleCompany(name, newValue) {
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  }

  const handleContactBy = input => e => {
    const { value, checked } = e.target;
    const { contact_by } = formData;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        contact_by: [...contact_by, value],
      }));

    } else {
      setFormData(prevState => ({
        ...prevState,
        contact_by: contact_by.filter((e) => e !== value),
      }));

    }
  }



  const [value, setValue] = useState('');
  const [category, setCategory] = useState('');
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }



  switch (step) {
    case 1:
      return (
        <>
          <OrgSignupStepOne nextStep={nextStep} handleImage={handleImage} handleFormData={handleInputData} handleCompany={handleCompany} values={formData} />
        </>
      )
    case 2:
      return (
        <>
          <OrgSignupStepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleContactBy={handleContactBy} values={formData} />
        </>
      )
    case 3:
      return (
        <>
          <OrgSignupStepThree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
        </>
      )
    case 4:
      return (
        <>
          <OrgSignupStepFour values={formData} prevStep={prevStep} handleCompany={handleCompany} />
        </>
      )
    // never forget the default case, otherwise VS code would be mad!
    default:
    // do nothing
  }
}

export default OrgRegistration
