import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import config from '../../config';
import { FecthData } from '../../Services/FetchData';
import { Admin } from '../../Services/Admin';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NotificationManager } from 'react-notifications';
import FieldErrors from '../../Services/FieldErrors';
import ActionButton from '../ActionButton';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function AdminAuthorizations() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [authorization, setAuthorization] = useState([])
  const [error, setError] = useState()
  const [fieldError, setFieldError] = useState()
  const history = useHistory();
  const [state, setState] = useState({
    action: "",
    admin: "Yes",
    company: "Yes",
    school: "Yes",
    student: "Yes",
    tutor: "Yes",
    facilitator: "Yes",
    parent: "Yes"
  })

  const addUserFunction = () => {
    Admin.post(state, 'users/create_permission').then(
      (data) => {
        if (data.data) {
          NotificationManager.success(data.message, 'Success!')
          setState({ ...state, action: '' })
          handleClose()
        } else {
          setFieldError(data.errors.details)
          NotificationManager.error(data.message, 'Error!')
        }
      }

    )
  }
  const updateUserAction = (id, user_type, value) => {
    const data = {
      id: id,
      user_type: user_type,
      value: value
    }
    Admin.post(data, 'users/update_permission').then(
      (data) => { }
    )
  }

  useEffect(() => {
    FecthData.getData('users/get_all_permissions_list').then(
      (data) => setAuthorization(data.data),
      (error) => setError(error.toString())
    );
  }, [])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={12} className="mt-2">
                  <div className="d-flex align-items-center">
                    {/* <div className="school-logo-small">
                      <img src="images/school-logo3.png" alt="" />
                    </div> */}
                    <h4 className="text-muted mb-0">Authorizations</h4>
                  </div>
                </Col>
                {/* <Col md={12} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        <Form.Group>
                          <Form.Select>
                            <option value="0">Organization Type</option>
                            <option value="1">All</option>
                            <option value="2">School</option>
                            <option value="3">Organization</option>
                            <option value="4">DasyaUSA</option>
                          </Form.Select>
                        </Form.Group>
                      </li>
                      <li>
                        <Form.Group>
                          <Form.Select>
                            <option value="0">Organization Code</option>
                            <option value="1">All</option>
                            <option value="2">#66548</option>
                            <option value="3">#89589</option>
                            <option value="4">#76167</option>
                          </Form.Select>
                        </Form.Group>
                      </li>
                      <li>
                        <Form.Group>
                          <Form.Select>
                            <option value="0">Student/Mentee</option>
                            <option value="1">Tutor/Mentor</option>
                            <option value="2">Parent/Guardian</option>
                            <option value="3">Facilitator</option>
                          </Form.Select>
                        </Form.Group>
                      </li>
                      <li onClick={handleShow}>
                        <a className="btn btn-primary" >Add User Function <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col> */}
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">User Function</th>
                          <th scope="col">Student/Mentee</th>
                          <th scope="col">Tutor/Mentor</th>
                          <th scope="col">Parent/Guardian</th>
                          <th scope="col">Facilitator</th>
                          <th scope="col">School Admin</th>
                          <th scope="col">Company Admin</th>
                          <th scope="col">Dasya Admin</th>
                        </tr>
                      </thead>
                      <tbody>
                        {authorization.map(auth => <tr>
                          <td>{auth?.action}</td>
                          <td>
                            <select defaultValue={auth?.student} onChange={(e) => updateUserAction(auth?._id, 'student', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={"No"}>No</option>
                              {/* <option value={'Maintain'}>Maintain</option>
                              <option value={'Inquiry'}>Inquiry</option> */}
                            </select>
                          </td>
                          <td>
                            <select defaultValue={auth?.tutor} onChange={(e) => updateUserAction(auth?._id, 'tutor', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={'No'}>No</option>
                              {/* <option value={'Confirm'}>Confirm</option> */}
                            </select>
                          </td>
                          <td>
                            <select defaultValue={auth?.parent} onChange={(e) => updateUserAction(auth?._id, 'parent', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={'No'}>No</option>
                              {/* <option value={'Add+Maintain'}>Add+Maintain</option>
                              <option value={'Deny'}>Deny</option>
                              <option value={'Enable'}>Enable</option>
                              <option value={'Inquiry'}>Inquiry</option>
                              <option value={'Maintained'}>Maintained</option>
                              <option value={'Not Enabled'}>Not Enabled</option> */}
                            </select>
                          </td>
                          <td>
                            <select defaultValue={auth?.facilitator} onChange={(e) => updateUserAction(auth?._id, 'facilitator', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={'No'}>No</option>
                              {/* <option value={'Add+Maintain'}>Add+Maintain</option>
                              <option value={'Deny'}>Deny</option>
                              <option value={'Enable'}>Enable</option>
                              <option value={'Inquiry'}>Inquiry</option>
                              <option value={'Maintained'}>Maintained</option>
                              <option value={'Not Enabled'}>Not Enabled</option> */}
                            </select>
                          </td>
                          <td>
                            <select defaultValue={auth?.school} onChange={(e) => updateUserAction(auth?._id, 'school', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={'No'}>No</option>
                              {/* <option value={'Add+Maintain'}>Add+Maintain</option>
                              <option value={'Deny'}>Deny</option>
                              <option value={'Enable'}>Enable</option>
                              <option value={'Inquiry'}>Inquiry</option>
                              <option value={'Maintained'}>Maintained</option>
                              <option value={'Not Enabled'}>Not Enabled</option> */}
                            </select>
                          </td>
                          <td>
                            <select defaultValue={auth?.company} onChange={(e) => updateUserAction(auth?._id, 'company', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={'No'}>No</option>
                              {/* <option value={'Add+Maintain'}>Add+Maintain</option>
                              <option value={'Deny'}>Deny</option>
                              <option value={'Enable'}>Enable</option>
                              <option value={'Inquiry'}>Inquiry</option>
                              <option value={'Maintained'}>Maintained</option>
                              <option value={'Not Enabled'}>Not Enabled</option> */}
                            </select>
                          </td>
                          <td>
                            <select defaultValue={auth?.admin} onChange={(e) => updateUserAction(auth?._id, 'admin', e.target.value)}>
                              <option value={"Yes"}>Yes</option>
                              <option value={'No'}>No</option>
                              {/* <option value={'Add+Maintain'}>Add+Maintain</option>
                              <option value={'Deny'}>Deny</option>
                              <option value={'Enable'}>Enable</option>
                              <option value={'Inquiry'}>Inquiry</option>
                              <option value={'Maintained'}>Maintained</option>
                              <option value={'Not Enabled'}>Not Enabled</option> */}
                            </select>
                          </td>
                        </tr>)}
                        {/* <tr>
                          <td>Login</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Account Creation</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Parental Consent</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Dashboard</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>My Schedule</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Chats</td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Live Classroom</td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Progress</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Resources</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Settings</td>
                          <td>
                            <select>
                              <option>Add+Maintain+Inquiry</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Deny</option>
                              <option>Confirm</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                          <td>
                            <select>
                              <option>Add</option>
                              <option>Add+Maintain</option>
                              <option>Deny</option>
                              <option>Enable</option>
                              <option>Inquiry</option>
                              <option>Maintained</option>
                              <option selected>Not Enabled</option>
                            </select>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {/* <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/school-logo3.png" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action">View Profile</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>Mather High School</h5>
                  <h6>ID: 567872</h6>
                  <a href="#" className="btn btn-primary solid btn-sm no-shadow d-block">Activate Account</a>
                </div>
                <Form>
                  <Row>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="John" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="Doe" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="Dean" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="5835 N Lincoln Ave, Chicago, IL 60659, USA" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey">
                        <option>State</option>
                        <option value="1" selected>Illinois</option>
                        <option value="2">Illinois</option>
                        <option value="3">Illinois</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="60659" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="tel" value="312-672-8763" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="email" value="mather@edu" />
                    </Form.Group>
                    {/* <Col md={12}>
                      <Form.Group className="iagree mb-2" controlId="formbymail">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Email" checked />
                      </Form.Group>
                      <Form.Group className="iagree mb-2" controlId="formbyphone">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Phone" />
                      </Form.Group>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill no-shadow" variant="primary">Admit</Button>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill" variant="secondary">DECLINE</Button>
                    </Col> // closing comment teg
                  </Row>
                </Form>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User Function </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Label>Action/Function</Form.Label>
                  <Form.Control type="text" placeholder="Action/Function Name" value={state?.action} onChange={(e) => setState({ ...state, action: e.target.value })} />
                  <FieldErrors errors={fieldError} field="action" />
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact First Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact Last Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="text" placeholder="School Address" />
                </Form.Group> */}
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Zip Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="text" placeholder="Contact Number" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group> */}
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>Student Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, student: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={"Add"}>Add</option>
                    <option value={'Maintain'}>Maintain</option>
                    <option value={'Inquiry'}>Inquiry</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>Tutor Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, tutor: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={'Deny'}>Deny</option>
                    <option value={'Confirm'}>Confirm</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>Parent Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, parent: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={'Add'}>Add</option>
                    <option value={'Add+Maintain'}>Add+Maintain</option>
                    <option value={'Deny'}>Deny</option>
                    <option value={'Enable'}>Enable</option>
                    <option value={'Inquiry'}>Inquiry</option>
                    <option value={'Maintained'}>Maintained</option>
                    <option value={'Not Enabled'}>Not Enabled</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>Facilitator Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, facilitator: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={'Add'}>Add</option>
                    <option value={'Add+Maintain'}>Add+Maintain</option>
                    <option value={'Deny'}>Deny</option>
                    <option value={'Enable'}>Enable</option>
                    <option value={'Inquiry'}>Inquiry</option>
                    <option value={'Maintained'}>Maintained</option>
                    <option value={'Not Enabled'}>Not Enabled</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>School Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, school: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={'Add'}>Add</option>
                    <option value={'Add+Maintain'}>Add+Maintain</option>
                    <option value={'Deny'}>Deny</option>
                    <option value={'Enable'}>Enable</option>
                    <option value={'Inquiry'}>Inquiry</option>
                    <option value={'Maintained'}>Maintained</option>
                    <option value={'Not Enabled'}>Not Enabled</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>Company Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, company: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={'Add'}>Add</option>
                    <option value={'Add+Maintain'}>Add+Maintain</option>
                    <option value={'Deny'}>Deny</option>
                    <option value={'Enable'}>Enable</option>
                    <option value={'Inquiry'}>Inquiry</option>
                    <option value={'Maintained'}>Maintained</option>
                    <option value={'Not Enabled'}>Not Enabled</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Label>Admin Permission</Form.Label>
                  <Form.Select defaultValue={"Yes"} onChange={(e) => setState({ ...state, admin: e.target.value })}>
                    <option value={"Yes"}>Yes</option>
                    <option value={'Add'}>Add</option>
                    <option value={'Add+Maintain'}>Add+Maintain</option>
                    <option value={'Deny'}>Deny</option>
                    <option value={'Enable'}>Enable</option>
                    <option value={'Inquiry'}>Inquiry</option>
                    <option value={'Maintained'}>Maintained</option>
                    <option value={'Not Enabled'}>Not Enabled</option>
                  </Form.Select>
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  {/* <ActionButton title={'Submit'} type={'button'} onClick={addUserFunction} requiredFields={[state?.action]} /> */}
                  <Button className="px-4 btn-pill" variant={state?.action ? "primary" : 'secondary'} onClick={addUserFunction} disabled={state?.action == ''}>Submit</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AdminAuthorizations
