import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import Autocomplete from 'react-autocomplete';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../config";
import { useHistory } from 'react-router-dom';
import { FecthData } from '../../Services/FetchData';
import FieldErrors from '../../Services/FieldErrors';
import ToastShow from '../common/ToastShow';
import ToastModal from '../common/ToastModal';
import { FiEdit } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import { Student } from '../../Services/Student';
import { GoDotFill } from 'react-icons/go';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function AdminProfileSetting() {

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    streem_id: '',
    icon_image: '',
    gender_id: ''
  })
  const handleClose = () => {
    setShow(false)
    setMode('')
    setFormData({ ...formData, title: '', streem_id: '', icon_image: '' })
  };
  const handleShow = () => setShow(true);
  const [showDelCon, setShowDelCon] = useState(false);
  const handleDelConClose = () => setShowDelCon(false);
  const handleDelConShow = () => setShowDelCon(true);
  const [showToast, setShowToast] = useState(false)
  const [modalBody, setModalBody] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [bg, setBg] = useState('')
  const [value, setValue] = useState('');
  const [school, setSchool] = useState({});
  const [student, setStudent] = useState([]);
  const [index, setIndex] = useState(0)
  const [selectedResource, selectResource] = useState();
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [filter, setFilter] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const { id } = useParams();
  const history = useHistory()
  const [tab, setTab] = useState(1)
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [genderAvatars, setGenderAvatars] = useState([]);
  const [genders, setGenders] = useState([]);
  const [streems, setStreems] = useState([]);
  const [mode, setMode] = useState('create')

  const onToggelToast = () => {
    setShowToast(!showToast)
  }
  const getStudentsOfSchool = () => {
    FecthData.getData('student/get_all_student_of_school/' + id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setStudent(data?.data)
        // selectStudent(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  const getAllStreem = () => {
    FecthData.getData('streem_and_subject/get_all_streem').then(
      (data) => {
        if (data?.data) {
          setStreems(data?.data)
        }
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      })
  }
  const getAllSubjects = () => {
    FecthData.getData('streem_and_subject/get_all_subject?filter=' + filter).then(
      (data) => {
        if (data.error === 0) {
          setSubjects(data?.data)
        }
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      })
  }
  const getAllGrades = () => {
    FecthData.getData('grades/get_all_grade?filter=' + filter).then(
      (data) => {
        if (data.error === 0) {
          setGrades(data?.data)
        }
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      })
  }
  const getAllAvatar = () => {
    Student.getDta({ page: 0 }, 'gender/get-all-gender-avatar?filter=' + filter).then(
      (data) => setGenderAvatars(data?.data),
      (error) => setError(error.toString())
    );
  }
  const getAllGender = () => {
    Student.getDta({ page: 0 }, 'gender/get_all_gender').then(
      (data) => setGenders(data?.data),
      (error) => setError(error.toString())
    );
  }
  useEffect(() => {
    getAllStreem()
    getAllSubjects()
    getAllGrades()
    getAllAvatar()
    getAllGender()
  }, [filter]);
  useEffect(() => {
    // getStudentsOfSchool()
  }, [filter])

  const updateSubject = (reqData) => {
    FecthData.update(reqData, 'streem_and_subject/update_subject/' + selectedResource._id).then(
      (data) => {
        setFormData({
          ...formData, title: '',
          streem_id: '',
          icon_image: '',
          gender_id: ''
        })
        getAllSubjects()
        NotificationManager.success(data.message, 'success!', 2000);
        handleClose()
      }
    )
  }
  const updateSubjectStatus = (id, status) => {
    let reqData = new FormData();
    reqData.append('status', status)
    FecthData.update(reqData, 'streem_and_subject/update_status/' + id).then(
      (data) => {
        setFormData({
          ...formData, title: '',
          streem_id: '',
          icon_image: '',
          gender_id: ''
        })
        getAllSubjects()
        NotificationManager.success(data.message, 'success!', 2000);
      }
    )
  }
  const addGrade = (reqData) => {
    FecthData.post(reqData, 'grades/create_grade').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)
          setFormData({
            ...formData, title: '',
            streem_id: '',
            icon_image: '',
            gender_id: ''
          })
          getAllGrades()
          handleClose()
        } else {
          setError(data.errors.details)
        }

      }
    ).catch(e => console.log(e))
  }
  const updateGrade = (reqData) => {
    FecthData.update(reqData, 'grades/update/' + selectedResource._id).then(
      (data) => {
        setFormData({
          ...formData, title: '',
          streem_id: '',
          icon_image: '',
          gender_id: ''
        })
        getAllGrades()
        handleClose()
        NotificationManager.success(data.message, 'success!', 2000);
      }
    )
  }
  const addGenderAvatar = (reqData) => {
    FecthData.post(reqData, 'gender/create-gender-avatar').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)
          getAllAvatar()
          setFormData({
            ...formData, title: '',
            streem_id: '',
            icon_image: '',
            gender_id: ''
          })
          handleClose()
        } else {
          setError(data.errors.details)

        }

      }
    ).catch(e => console.log(e))
  }
  const updateGenderAvatar = (reqData) => {
    FecthData.update(reqData, 'gender/update_gender_avatar/' + selectedResource._id).then(
      (data) => {
        setFormData({
          ...formData, title: '',
          streem_id: '',
          icon_image: '',
          gender_id: ''
        })
        getAllAvatar()
        handleClose()
        NotificationManager.success(data.message, 'success!', 2000);
      }
    )
  }
  const submitForm = () => {
    let reqData = new FormData();
    Object.entries(formData).map(([key, value]) => {
      reqData.append(key, value);
    })
    if (mode != 'edit') {
      if (tab == 1) {
        FecthData.post(reqData, 'streem_and_subject/create_subject').then(
          (data) => {
            if (data?.data) {
              NotificationManager.success(data.message, 'success!', 2000)

              getAllSubjects()
              handleClose()
            } else {
              setError(data.errors.details)

            }

          }
        )
      } else if (tab == 2) {
        addGrade(reqData)
      } else {
        addGenderAvatar(reqData)
      }
    } else {
      if (tab == 1) {
        updateSubject(reqData)
      } else if (tab == 2) {
        updateGrade(reqData)
      } else {
        updateGenderAvatar(reqData)
      }
    }
  }
  const changeUserStatus = (userId, changedStatus) => {
    var body = ''
    var title = ''
    var bg = ''
    if (changedStatus == 'Active') {
      body = 'Student admitted successfuly'
      title = 'Success!'
      bg = 'success'
    } else {
      body = 'Student declined successfuly'
      title = 'Declined!'
      bg = 'danger'
    }
    setModalBody(body)
    setModalTitle(title)
    setBg(bg)
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      setIndex(index)
      getStudentsOfSchool()
      onToggelToast()
    })
  }
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }

  const deleteResource = (id) => {
    if (tab == 1) {
      deleteSubject(id)
    } else if (tab == 2) {
      deleteGrade(id)
    } else {
      deleteAvatar(id)
    }
  }
  const deleteSubject = (id) => {
    FecthData.deleteAPI('streem_and_subject/delete_subject/' + id).then(
      (data) => {
        getAllSubjects()
        handleDelConClose()
      }
    )
  }
  const deleteGrade = (id) => {
    FecthData.deleteAPI('grades/delete/' + id).then(
      (data) => {
        getAllGrades()
        handleDelConClose()
      }
    )
  }
  const deleteAvatar = (id) => {
    FecthData.deleteAPI('gender/delete_avatar/' + id).then(
      (data) => {
        getAllAvatar()
        handleDelConClose()
      }
    )
  }
  const handleEdit = (title, streem_id, icon_image) => {
    setMode('edit')
    if (tab == 3) {
      setFormData({ ...formData, gender_id: streem_id, icon_url: icon_image })
    } else {
      setFormData({ ...formData, title: title, streem_id: streem_id, icon_url: icon_image })
    }
  }
  useEffect(() => {
    if (mode == 'edit') {
      handleShow()
    }
  }, [mode])

  function TabContent() {
    if (tab == 1) {
      return <SubjectsTab />
    } else if (tab == 2) {
      return <GradesTab />
    } else {
      return <AvatarTab />
    }
  }

  function SubjectsTab() {
    return <Col md={12}>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Icon</th>
              <th scope="col">Subject Category</th>
              <th scope="col">Subject</th>
              <th scope="col">Status</th>
              {/* <th scope="col">Email</th> */}
              {/* <th scope='col'>Edit</th> */}
            </tr>
          </thead>
          <tbody >
            {subjects?.map((content, index) => (
              <tr key={index}
                onClick={() => {
                  setIndex(index)
                  selectResource(content)
                }}
                style={{ cursor: 'pointer' }} >
                {/* {content?.title} */}
                <td className=''>
                  <div className='sub-img'>
                    {content?.icon_image && <img src={getResourceUrl(content?.icon_image)} alt="" width="44" className="rounded me-2" />}{!content?.icon_image && <img src={'images/no_img1.png'} alt="" width="44" className="rounded me-2" />}
                  </div>
                </td>
                <td>{content?.streem_id?.title}</td>
                <td>{content?.title}</td>
                <td><select className='bg-light rounded m-1' defaultValue={content?.status} onChange={e => updateSubjectStatus(content._id, e.target.value)}>
                  <option value={'Active'}>&#128994; Enabled</option>
                  <option value={'Inactive'}>&#128992; Disabled</option>
                </select></td>
                <td><FiEdit size={20} onClick={() => handleEdit(content.title, content.streem_id?._id, content?.icon_image)} /> &nbsp; &nbsp; &nbsp;<BsTrash3 size={20} onClick={() => handleDelConShow()} /></td>
              </tr>
            ))}


          </tbody>
        </table>
      </div>
    </Col>
  }
  function GradesTab() {
    return <Col md={12}>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Icon</th>
              <th scope="col">Grade</th>
              {/* <th scope="col">Status</th> */}
              {/* <th scope="col">Email</th> */}
              {/* <th scope='col'>Edit</th> */}
            </tr>
          </thead>
          <tbody className=''>
            {grades?.map((content, index) => (
              <tr key={index}
                onClick={() => {
                  setIndex(index)
                  selectResource(content)
                }}
                style={{ cursor: 'pointer' }} >
                {/* {content?.title} */}
                <td className='slctGrade'>
                  <div className='sub-img'>
                    {content?.icon_image && <img src={getResourceUrl(content?.icon_image)} alt="" width="44" className="rounded me-2" />}{!content?.icon_image && <img src={'images/no_img1.png'} alt="" width="44" className="rounded me-2" />}
                  </div>
                </td>
                <td>{content?.title}</td>
                <td>
                  {/* <select className='bg-white' defaultValue={content?.status}
                // onChange={e => updateSubjectStatus(content._id, e.target.value)}
                >
                  <option value={'Active'}>&#128994; Enabled</option>
                  <option value={'Inactive'}>&#128992; Disabled</option>
                </select> */}
                </td>
                <td><FiEdit size={20} onClick={() => handleEdit(content.title, content.streem_id?._id, content?.icon_image)} /> &nbsp; &nbsp; &nbsp;<BsTrash3 size={20} onClick={() => handleDelConShow()} /></td>
              </tr>
            ))}


          </tbody>
        </table>
      </div>
    </Col>
  }
  function AvatarTab() {
    return <>{genderAvatars?.map(genAvatar => <>{genAvatar?.avatars?.length > 0 ? <h5 className="text-muted mb-0">{genAvatar?.name}</h5> : null} {genAvatar?.avatars?.map(avatar => <Col md={3} onClick={() => {

      selectResource(avatar)
    }}>
      <div className="video-box bg-white m-2 shadow-lg p-1">
        <img className='mt-2' src={avatar.icon_image ? getResourceUrl( avatar.icon_image ): "images/no_profile_img.png"} height={200} width={200} alt="" />
        {/* <div className="video-box-text">
            <h4>Functions & Graphing</h4>
            <p>1:30 min</p>
          </div> */}
        <a href="javascript:void(0)" className="watch-btn-option d-flex align-items-end flex-column">
          <BsTrash3 size={30} onClick={() => handleDelConShow()} className='mb-2' />
          <FiEdit size={30} onClick={() => handleEdit(avatar?.name, avatar?.gender_id, avatar?.icon_image)} />
          {/* <img src="images/three-dot.png" alt="" width="20" /> */}
        </a>
        {/* <a href="javascript:void(0)" className="watch-btn">
            <img src="images/play-icon.png" alt="" />
          </a> */}
      </div>
    </Col>)}</>)}</>
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={3} className="mt-2">
                  <div className="d-flex align-items-center">
                    {/* <div className="school-logo-small">
                      <img src="images/school-logo3.png" alt="" />
                    </div> */}
                    <h4 className="text-muted mb-0">Profile Setting</h4>
                  </div>

                </Col>
                <Col md={9} className="mt-2">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select size='lg' compon style={{
                          cursor: 'pointer'
                        }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort </option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                          <img src="images/filter-icon.png" alt="" width="13" />
                        </Form.Select>
                      </li>
                      <li onClick={handleShow}>
                        <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category d-flex">
                    <ul>
                      <li className={tab == 1 ? "active" : ''} onClick={() => setTab(1)}>
                        <Link to={'#'} >Subjects</Link>
                      </li>
                      <li className={tab == 2 ? "active" : ''} onClick={() => setTab(2)}>
                        <Link to={'#'}>Grades</Link>
                      </li>
                      <li className={tab == 3 ? "active" : ''} onClick={() => setTab(3)}>
                        <Link to={'#'}>Avatar</Link>
                      </li>
                      {/* <li>
                        <Link to={"/admin/school/parent/" + id}>Parents/Guardians</Link>
                      </li>
                      <li>
                        <Link
                          to={'/admin/school/admin/' + id}
                        >School Admin</Link>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <TabContent selectedTab={tab} />

              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {/* {selectedStudent ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedStudent?.gender?.length > 0 ? selectedStudent?.gender[0]?.icon_image : "images/avatar2.png"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item ><Link to={{ pathname: "/school-students-profile", state: selectedStudent }}>View Profile</Link></Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item ><Link to={{ pathname: "/admin/user/edit", state: { ...selectedStudent, user_role: 'student', status: selectedStudent?.user_details[0].status, name: selectedStudent?.user_details[0].name } }}></Link>Edit Account </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="javascript:void(0)">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{selectedStudent?.first_name} {selectedStudent?.last_name}</h5>
                  <h6>Student ID: {selectedStudent?.student_code}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedStudent?.phone}`}>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedStudent?.phone ? <span className='show-phone'>{selectedStudent?.phone}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedStudent?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedStudent?.user_details[0].status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedStudent?.user_details[0].status}</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>{selectedStudent?.about}
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>{selectedStudent?.gender[0]?.name}</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>{selectedStudent?.email}</p>
                  <h4>Parent’s Email</h4>
                  <p>{selectedStudent?.guardian_email}</p>
                  <h4>Facilitator Email:</h4>
                  <p>{selectedStudent?.facilitator_email}</p>
                  <h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedStudent?.subjects.map(subject => <li>{subject}</li>)}
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
                <ToastModal show={showToast} onHide={onToggelToast} bg={bg} centered={true} modalTitle={modalTitle} modalBody={modalBody} />
                
                {selectedStudent?.user_details[0].status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedStudent?.user_id, "Active")}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedStudent?.user_id, "Inactive")}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null} */}
          </div>
        </div>
      </section>

      <Modal show={showDelCon} onHide={handleDelConClose}
        size="md"
        centered
      >
        <Modal.Header className='text-center' >
          <Modal.Title >Delete Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <BsTrash3 size={50} className='mb-3' />
          <p>Are you sure you want to delete this {tab == 1 ? 'subject' : tab == 2 ? 'grade' : 'avatar'}?</p>
          <Form>
            <div className="modal-form">
              <Row>


                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary" onClick={() => deleteResource(selectedResource?._id)}>Ok</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header className='text-center' >
          <Modal.Title >{mode != 'edit' ? 'Add' : 'Edit'} {tab == 1 ? 'Subject' : tab == 2 ? 'Grade' : 'Avatar'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
                <Form.Group className="form-group mb-3">
                  <div className="upload-resource">
                    {mode != 'edit' || formData.icon_image ? <img src={formData.icon_image ? URL.createObjectURL(formData.icon_image) : "images/camera-icon.png"} alt=" " width="78" /> : <img src={formData.icon_url ? getResourceUrl(formData.icon_url) : "images/camera-icon.png"} alt=" " width="78" />}
                    <input type="file" onChange={(e) => setFormData({ ...formData, icon_image: e.target.files[0] })} />
                  </div>
                  <FieldErrors errors={error} field="icon_image" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  {tab != 3 ? <Form.Control type="text" placeholder="Title*" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} /> :
                    <Form.Select defaultValue={formData?.gender_id} onChange={e => setFormData({ ...formData, gender_id: e.target.value })}>
                      <option value={''} selected>Select Gender*</option>
                      {genders.map(gender => <option value={gender?._id}>{gender?.name}</option>)}
                    </Form.Select>
                  }
                  <FieldErrors errors={error} field="title" />
                </Form.Group>
                {tab == 1 && <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Select type="text" placeholder="Streem*" defaultValue={formData.streem_id} onChange={(e) => setFormData({ ...formData, streem_id: e.target.value })} >
                    <option selected value={''} >Streem*</option>
                    {streems?.map(streem => <option value={streem?._id}>{streem?.title}</option>)}
                  </Form.Select>
                  <FieldErrors errors={error} field="streem_id" />
                </Form.Group>}
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Student Contact Number*" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  <FieldErrors errors={error} field="phone" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Student Email*" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  <FieldErrors errors={error} field="email" />
                </Form.Group> */}
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Mather High School" placeholder="School Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="0221457" placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="5835 N LincoIn Ave" placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Chicago" placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Illinois" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="60659" placeholder="Zip Code" />
                </Form.Group> */}
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary"
                    disabled={(tab == 1 && (!formData.title || !formData.streem_id)) || (tab == 2 && !formData?.title) || (tab == 3 && (!formData?.gender_id || (!formData?.icon_image && mode != 'edit')))}
                    onClick={submitForm}>Submit</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AdminProfileSetting
