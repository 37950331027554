import { Col, Form, Row } from "react-bootstrap";
import CalendarUI from "../CalenderUI";
import TimePicker from "react-time-picker/dist/TimePicker";
// import TimePicker from 'react-bootstrap-time-picker';
import { timeFormate } from "../../helpers/utils";
import TimeSelect from "../common/TimeSelect";

export default function SessionForm({ mode, inputSlot, error1, subject, removeFields, selectedDay, handleChange,noSessionMsg }) {
    return <>
        {inputSlot.map((element, index) => <Row key={`slot-${index}`}>
            {mode == 'signup' ? <Col md={12} className="mb-3 text-end">
                {
                    index ?
                        <a className="btn btn-dark mb-3" onClick={() => removeFields(index)}><i className="fa fa-close"></i></a>
                        : null
                }
            </Col> : null}
            <Col md={12} className="mb-3">

                <CalendarUI onChange={(value, event) => selectedDay(index, value)} className='' />

                {/* <DatePicker getSelectedDay={e => selectedDay(index, e)}
endDate={100}
selectDate={new Date()}
name={'date'}
labelFormat={"MMMM"}
color={"#287AEB"}
labelColor={'red'}
marked={[
{
  date: new Date(2021, 9, 3),
  marked: true,
  style: {
    color: "#ff0000",
    padding: "2px",
    fontSize: 12,
  },
  text: "1x",
},
{
  date: new Date(2021, 9, 4),
  marked: true,
  text: "5x"
},
]}
/> */}
                <span className={`err date${index}`}>{error1?.[index]?.date}</span>
            </Col>
            {/* {element?.date && <Col md={12} className="mb-3 text-center">Date: {element?.date}</Col>} */}
            <Col md={6}>
                <Form.Group className="mb-3">
                    <Form.Label className='p-1'>Subject</Form.Label>
                    <Form.Select name="subject" onChange={e => handleChange(index, e)} defaultValue={element.subject}>
                        <option value={''} selected>Subject Selected*</option>
                        {subject && subject?.map((content, index) => <option key={index} value={content._id}>{content.title}</option>
                        )}
                    </Form.Select>
                    <span className={`err subject${index}`}>{error1?.[index]?.subject}</span>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-3">
                    <Form.Label className='p-1'>Session Type</Form.Label>
                    <Form.Select name="type_of_tutoring_session" onChange={e => handleChange(index, e)}>
                        <option value="">Type of tutoring session</option>
                        <option value="daily session">Daily session</option>
                        <option value="weekly session">Weekly session</option>
                        <option value="monthly session">Monthly session</option>
                    </Form.Select>
                    <span className={`err type_of_tutoring_session${index}`}>{error1?.[index]?.type_of_tutoring_session}</span>
                </Form.Group>
            </Col>
            <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Label className='p-1'> Start Time </Form.Label>
                {/* <Form.Control type="time" pattern="[0-12]{2}:[0-60]{2}" placeholder="Start Time*" name='start_time' onChange={e => handleChange(index, e)} /> */}
                <TimeSelect name="start_time" index={index} onChange={handleChange} value={element?.start_time} />
            </Form.Group>


            <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Label className='p-1'> End Time </Form.Label>
                {/* <Form.Control type="time" pattern="AM:PM" placeholder="End Time*" name='end_time' onChange={e => handleChange(index, e)} /> */}
                <TimeSelect name="end_time" index={index} onChange={handleChange} value={element?.end_time}/>
            </Form.Group>
            {/* <Col md={6}>
                
                <Form.Group className="mb-3">
                    <TimePicker label="Start Time" name="start_time" onChange={e => handleChange(index, e)} value={startTime[index]} renderInput={(params) => <TextField {...params} />} />
                    <span className={`err start_time${index}`}>{error1?.[index]?.start_time}</span>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-3">
                    <TimePicker name="end_time" label="End Time" onChange={e => handleChange(index, e)} value={endTime[index]} renderInput={(params) => <TextField {...params} />} />
                    <span className={`err end_time${index}`}>{error1?.[index]?.end_time}</span>
                </Form.Group>
            </Col> */}
            {/* <Col md={12}>
                <Form.Group className="mb-3">
                    <Form.Select name="tutor_session_type" onChange={e => handleChange(index, e)}>
                        <option value="">Select session Type</option>
                        <option value="live">Live</option>
                        <option value="on-demand">On Demand</option>
                    </Form.Select>
                    <span className={`err session${index}`}>{error1?.[index]?.session}</span>
                </Form.Group>
            </Col> */}
            {/* <Col md={12}>
<Form.Group className="mb-3">
<Form.Select name="tutor_session_id" onChange={e => handleChange(index, e)}>
<option value="">Select session*</option>
{element.tutor_sessions.map(session => <option value={session._id}>{session.type_of_tutoring_session}</option>)}
</Form.Select>
<span className={`err session${index}`}>{error1?.[index]?.session}</span>
</Form.Group>
</Col> */}
{noSessionMsg&&<Col className="alert alert-info">{noSessionMsg}</Col>}
            {element.current_day_sessions.length > 0 ? <Col md={12}>
                <p className='m-1'>{'Available session on selected Date '}</p>
                <ul className='mb-3'>

                    {element.current_day_sessions.map(session => <li className='m-1' key={session._id} style={{ fontSize: '16px' }}>
                        <input type="radio" name="tutor_session_id" value={session._id} onChange={(e) => handleChange(index, e)} checked={(element.tutor_session_id == session._id)} />
                        {" " + timeFormate(session?.start_time)} - {timeFormate(session?.end_time) + " | " + new Date(session?.date).toDateString() + " | Session with " + session?.tutor_id?.first_name + " " + session?.tutor_id?.last_name}
                    </li>)}

                </ul>
            </Col> : null}
            {element.upcoming_days_sessions.length > 0 && mode == 'dashboard' ? <Col md={12}>
                <p className='m-1'>Next available class:</p>
                <ul className='mb-3'>

                    {element.upcoming_days_sessions.map(session => <li className='m-1' key={session._id} style={{ fontSize: '16px' }}>
                        <input type="radio" name="tutor_session_id" value={session._id} onChange={(e) => handleChange(index, e)} checked={(element.tutor_session_id == session._id)} />
                        {" " + timeFormate(session.start_time)} - {timeFormate(session.end_time) + " | " + new Date(session?.date).toDateString() + " | Session with " + session?.tutor_id?.first_name + " " + session?.tutor_id?.last_name}
                    </li>)}

                </ul>
            </Col> : null}
            {/* {element.tutor_sessions.map(session => <Col > <option value={session._id}>{session.type_of_tutoring_session}</option> </Col>)} */}

            {/* <Col md={6}>
<Form.Group className="mb-3" controlId="formBasicCheckbox">
<Form.Check className="text-grey" type="radio" name={`recurring_option${index}`} id="flexRadioDefault1" onChange={e => handleChangeSetData(index, 'recurring_option', scheduleDay[index])} label={`Apply the schedule to all ${scheduleDay[index]}`} defaultValue={scheduleDay[index]} />
<span className={`err recurring_option${index}`}>{error1?.[index]?.recurring_option}</span>
</Form.Group>
</Col>
<Col md={6}>
<Form.Group className="mb-3" controlId="formBasicCheckbox1">
<Form.Check className="text-grey" type="radio" name={`recurring_option${index}`} id="flexRadioDefault2" onChange={e => handleChangeSetData(index, 'recurring_option', scheduleDate[index])} label={`Apply the schedule to ${scheduleDate[index]}`} defaultValue={scheduleDate[index]} />
</Form.Group>
</Col> */}

        </Row >)}</>
}