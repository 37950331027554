import React, { useState, Fragment } from "react";
import { Redirect, Outlet, Route, useHistory } from "react-router-dom";
import { useUserRoles } from "./useUserRoles";


// function ActivityMonitor(props) {


//   console.log("called")




//   return (
//     <div>

//       {props.children}

//     </div>
//   )


// }


//function ActivityMonitor(renderMaterial) {

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningTime: 1000 * 60 * 15,
      signoutTime: 1000 * 60 * 20,
    };
  }

  componentDidMount() {
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    console.log("reset")
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    window.alert("You will be logged out automatically in 5 minute")
    console.log('You will be logged out automatically in 1 minute.');
  };

  logout = () => {
    // Send a logout request to the API
    console.log('Sending a logout request to the API...');
    this.destroy();
  };

  destroy = () => {
    //clear the session
    //sbrowserHistory.push('/');
    window.location.assign(this.props.redirect);
  };

  render() {

    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
//}


export function RolesAuthRoute({ children, ...rest }) {

  const canAccess = rest.roles.some(userRole => localStorage.getItem("user_type") === userRole)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        canAccess ? (

          <AutoLogout {...rest} >{children}</AutoLogout>

        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}