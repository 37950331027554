import { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap"
import { Auth } from "../Services/Auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ForgotPassword = ({ show, handleClose}) => {
    const [validated, setValidated] = useState(null);
    const [message, setMessage]=useState(null)
    const [email,setEmail]=useState('')
    const history=useHistory()
    const [isSent,setIsSent]=useState(false)
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setMessage('Invalid Email')
        }else{
            setValidated(true);
            Auth.passwordReset({email:email}).then(
                (data)=>{
                    if(data.data){
                        setIsSent(true)
                    }else if(data?.errors){
                        setIsSent(false)
                    }
                    setMessage(data.message)
                    
                    // setInterval(handleClose, 1000);
                }
            )
        }
        
    }
    const onSuccess=()=>{
        setEmail('')
        setIsSent(false)
        setMessage(null)
        handleClose()
    }
    const onModalHide=()=>{
        setEmail('')
        if(!isSent){
            setMessage(null)
        }
        handleClose()
    }

    return <Modal show={show} onHide={onModalHide}
        size="sm"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="text-center mb-3">
                <img src="/images/lock-pass-icon.png" alt="" width="79.4" />
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {!isSent?<><Form.Group className="form-group mb-3" as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                    />
                    <span className="form-icon">
                        <img src="/images/user-icon.png" alt="" />
                    </span>
                </Form.Group><p className="modal-text text-center text-danger">
                    {message}
                </p><div className="text-center">
                    <Button variant="primary" type="submit" className="px-5">
                        Reset
                    </Button>
                </div></>:<div className="text-center"><div className="alert alert-success">{message}</div>
                <Button variant="primary"  className="px-5" onClick={onSuccess}>Ok</Button></div>}
                
                
            </Form>
        </Modal.Body>
    </Modal>
}
export default ForgotPassword