import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TutorSideMenu from "./TutorSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import _ from 'lodash';
import { FecthData } from '../../Services/FetchData';
import TutorSessionCalendar from './TutorSessionCalendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GraphUI from '../common/GraphUI';
import { formatDate, getWeekOfMonth } from '../../helpers/utils';
import NotificationUI from '../common/NotificationUI';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const data = {
  labels,
  datasets: [
    {
      label: 'Algebra',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#914CE9',
      ],
    },
    {
      label: 'Chemistry',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#FEB41C',
      ],
    },
    {
      label: 'Physics',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#D0202C',
      ],
    },
    {
      label: 'Geometry',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#73913E',
      ],
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function TutorStudentDetail(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const [interval, setInterval] = useState('weekly')
  const [labels, setLabels] = useState(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'])
  const [datasets, setDatasets] = useState(data.datasets)
  const colorObj = {
    Algebra: '#914CE9',
    Chemistry: '#FEB41C',
    Physics: '#D0202C',
    Geometry: '#73913E',
    Biology: '#023e8a',
    Calculus: '#249DA1'
  }
  const [statistics, setStatistics] = useState()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const student = props.location.state
  console.log(student)
  const [sessions, setSessions] = useState([])
  const [selectedDate, selectDate] = useState(formatDate(new Date()))
  const [dateWiseSessions, setDateWiseSessions] = useState([])
  const history = useHistory()
  const selectedDay = val => {
    const date = new Date(val)
    selectDate(formatDate(date))
  };
  const getTutorSession = () => {
    FecthData.getData('tutor/get_tutor_session/' + user?.tutor?._id+'?date='+selectedDate).then(
      (data) => setSessions(data?.data)
    )
  }
  const getStudentProgress = () => {
    FecthData.getData('student/get_student_progress/' + student?._id + '?interval=' + interval).then(
      (data) => {
        var labels1 = interval == 'weekly' ? ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] : ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4', 'WEEK 5'];
        let arr1 = []
        data?.data.map(pr => {
          var obj = {
            label: pr?._id?.subject_name,
            data: interval == 'weekly' ? {
              MONDAY: new Date(pr?._id?.date).getDay() == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              TUESDAY: new Date(pr?._id?.date).getDay() == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              WEDNESDAY: new Date(pr?._id?.date).getDay() == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              THURSDAY: new Date(pr?._id?.date).getDay() == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
              FRIDAY: new Date(pr?._id?.date).getDay() == 5 ? (pr.total_hours / 60).toFixed(2) : 0,
              SATURDAY: new Date(pr?._id?.date).getDay() == 6 ? (pr.total_hours / 60).toFixed(2) : 0,
              SUNDAY: new Date(pr?._id?.date).getDay() == 7 ? (pr.total_hours / 60).toFixed(2) : 0,
            } : {
              'WEEK 1': getWeekOfMonth(pr?._id?.date) == 0 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 2': getWeekOfMonth(pr?._id?.date) == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 3': getWeekOfMonth(pr?._id?.date) == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 4': getWeekOfMonth(pr?._id?.date) == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 5': getWeekOfMonth(pr?._id?.date) == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
            },
            backgroundColor: [
              colorObj[`${pr?._id?.subject_name}`]
            ],
          }
          arr1.push(obj)
        })
        setLabels(labels1)
        setDatasets(arr1)
      }
    )
  }
  const getStudentStatistics = () => {
    FecthData.getData('student/get_student_progress_count/' + student?._id).then(
      (data) => {
        setStatistics(data?.data)

      }
    )
  }
  useEffect(() => {
    getStudentStatistics()
  }, [])
  useEffect(() => {
    getStudentProgress()
  }, [interval])
  const getDateWiseSession = () => {
    FecthData.getData('tutor/get_tutor_session/' + user?.tutor?._id+'?date='+selectedDate).then(
      (data) => setDateWiseSessions(data?.data)
    )
  }
  useEffect(() => {
    getDateWiseSession()
  }, [selectedDate])
  const logout = () => {
    localStorage.clear()
    history.push('/tutor')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <TutorSideMenu />
          <div className="middle">
            <Col md={12}>
              <Form.Group className="form-group mb-2">
                <Form.Control type="text" placeholder="Search" className="search-input" />
                <Button variant="primary" type="submit" className="search-btn">
                  <img src="images/search-icon.png" alt="" width="22" />
                </Button>
              </Form.Group>
            </Col>
            <Col md={12}>
              <div className="card">
                <Row>
                  <Col md={9} className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="school-logo-small">
                        {/* <img src={student?.gender_ava"images/avatar2.png"} alt="" /> */}
                      </div>
                      <div className="ms-1">
                        <h4 className="text-muted mb-0">ID: {student?.student_code}</h4>
                        <h5 className="text-muted mb-0">Grade: 8 | Mather High School, IL</h5>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="mt-2">
                    <div className="tutor-action">
                      <ul className="justify-content-end">
                        <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <GraphUI state={{ labels: labels, datasets: datasets }} statistics={statistics} setInterval={setInterval} />
                  {/* <Col md={4}>
                    <div className="card bg-purple progress-box">
                      <div className="card-text text-white">
                        <img src="images/hat-icon.png" alt="" width="70" />
                        <h4>Scheduled Classes</h4>
                      </div>
                      <div className="card-big-text">
                        <h5 className="count">15</h5>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="card bg-danger progress-box">
                      <div className="card-text text-white">
                        <img src="images/book-icon.png" alt="" width="58" />
                        <h4>Assignments Completed</h4>
                      </div>
                      <div className="card-big-text">
                        <h5 className="count">07</h5>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="card bg-success progress-box">
                      <div className="card-text text-white">
                        <img src="images/timer-icon.png" alt="" width="34" />
                        <h4>Time Spent On Dasya</h4>
                      </div>
                      <div className="card-big-text">
                        <h5 className="count">14 <small>HRS</small></h5>
                      </div>
                    </div>
                  </Col>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between">No. Of Hrs Spent Subject Wise
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Bar options={options} data={data} /> */}
                </Row>
              </div>
            </Col>
            {/* <Row>
              <Col md={4}>
                <div className="card text-center">
                  <h5 className="sub-heading text-muted">Assignments</h5>
                  <img src="images/book-icon-blue.png" alt="" width="96" style={{ margin: auto, marginTop: 5, marginBottom: 10, }} />
                  <a href="javascript:void(0)" className="btn btn-primary btn-pill attach-assignment">
                    Upload Assignments
                    <input type="file" />
                  </a>
                </div>
              </Col>
              <Col md={8}>
                <div className="card">
                  <h5 className="sub-heading text-muted d-flex justify-content-between">Badges & Rewards <span><a className=" text-muted" href="/teacher-review">See All</a></span></h5>
                  <div className="badges">
                    <ul>
                      <li>
                        <img src="images/badge1.png" alt="" width="80" />
                        <h6>SCHEDULE 5+ Sessions</h6>
                      </li>
                      <li>
                        <img src="images/badge2.png" alt="" width="50" />
                        <h6>CHAT WITH TUTOR</h6>
                      </li>
                      <li>
                        <img src="images/badge3.png" alt="" width="64" />
                        <h6>REACH A NEW LEVEL</h6>
                      </li>
                      <li>
                        <img src="images/badge4.png" alt="" width="90" />
                        <h6>COMPLETE AN ASSIGNMENT</h6>
                      </li>
                      <li>
                        <Link onClick={handleShow}>
                          <img src="images/add-icon-blue.png" alt="" width="66" />
                          <h6 className="text-info">ADD A BADGE</h6>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row> */}
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.tutor?.org_type == 'school' ? user?.tutor_profile?.school_name : user?.tutor_profile?.company_name}</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <TutorSessionCalendar dateWiseSessions={dateWiseSessions} selectedDay={selectedDay} />
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a Badge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Col md="12">
                  <Form.Group className="form-group mb-3" as={Col} md="12">
                    <div className="attach-file-res mt-3">
                      <img src="images/attach-file.png" alt="" width="136" />
                      <input type="file" />
                    </div>
                  </Form.Group>
                </Col>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="text" placeholder="Badge Title" />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill me-2" variant="primary">Add</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TutorStudentDetail
