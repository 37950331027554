import { Button, Col } from "react-bootstrap";
import config from "../../config";
import Countdown from "react-countdown";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {timeFormate, utcToTzDate} from "../../helpers/utils";
import Video from "./Video";
import { getResourceUrl } from "../../Services/CommanFunctions";

export default function MyScheduleUI({sessions,role}){
  const user = JSON.parse(localStorage.getItem('user'))
  const history=useHistory()
  const handleOnClick=(tutor_id)=>{
    if(role=='student'){
      history.push('tutor-profile/'+tutor_id)
    }
    console.log("tuter redirect clicked")
  }
  console.log(sessions)
    return <Col md={12}>
    <div className="tutor-list mt-3">
      {sessions?.map(session => <><div className="class-date-time">
        <ul>
          <li>
            {timeFormate(session?.start_time)} - {timeFormate(session?.end_time)}
          </li>
          <li>
            {utcToTzDate(session?.date,'dddd, MMM DD')}
          </li>
        </ul>
      </div>
        <div className={`tutor-item ${new Date(session?.start_time).getDate() == new Date().getDate() ? 'active' : ''}`}>
          <div className="tutor-wrap">
            <div className="tutor-avatar" role="button" onClick={()=>handleOnClick(session?.tutor_id?._id)}>
              {role!='tutor'?<img src={session?.tutor_id?.user_id?.propic ? getResourceUrl(session?.tutor_id?.user_id?.propic)  : "images/no_profile_img.png"} alt="" />:<img src={user?.propic? getResourceUrl(user?.propic)  : "images/no_profile_img.png"} alt="" />}
            </div>
            <div className="tutor-subject">
                {role=='student'?<h6>{session?.tutor_id?.first_name} {session?.tutor_id?.last_name}</h6>:null}
              {/* <h6>ID: 567876</h6> */}
              <h5>Subject: {session?.subject?.title} </h5>
              {/* <p>Facilitator: Jane Doe | Session: Virtual</p> */}
              {/* {new Date(session?.start_time).getDate() == new Date().getDate() ? <Video                 
                role={role}
                session={session} ></Video>
                :<> */}
                <Video 
                role={role}
                session={session} ></Video>
                <Button variant="light" className="px-5 mt-3"> Time Left <Countdown
                date={session?.start_time}
                daysInHours="true"
              /></Button>
              {/* </> } */}

                

            </div>
          </div>
          <div className="tutor-icon">
            <img src={'images/' + session?.subject?.title?.toLowerCase() + '.png'} alt="" />
            {/* <img src="images/algebra.png" alt="" /> */}
          </div>
        </div></>)}
        {sessions.length==0?<div className="text-center m-1" style={{fontSize:'20px'}}>No upcoming sessions found!</div>:null}
      {/* <div className="class-date-time">
        <ul>
          <li>
            12:00 PM-1:00PM
          </li>
          <li>
            Monday, Aug 06
          </li>
        </ul>
      </div>
      <div className="tutor-item">
        <div className="tutor-wrap">
          <div className="tutor-avatar">
            <img src="images/avatar.jpg" alt="" />
          </div>
          <div className="tutor-subject">
            <h6>Sandra D.</h6>
            <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
            <p>Facilitator: Jane Doe | Session: Virtual</p>
            <Button variant="light" className="px-5 mt-3"> Time Left 2:55:00</Button>
          </div>
        </div>
        <div className="tutor-icon">
          <img src="images/geometry.png" alt="" />
        </div>
      </div>
      <div className="class-date-time">
        <ul>
          <li>
            05:00 PM-06:00 PM
          </li>
          <li>
            Monday, Aug 06
          </li>
        </ul>
      </div>
      <div className="tutor-item">
        <div className="tutor-wrap">
          <div className="tutor-avatar">
            <img src="images/avatar-male.jpg" alt="" />
          </div>
          <div className="tutor-subject">
            <h6>David T.</h6>
            <h5>Geography Chapter 4: Cultural Geography</h5>
            <p>Facilitator: Jane Doe | Session: Virtual</p>
            <Button variant="light" className="px-5 mt-3"> Time Left 5:30:00</Button>
          </div>
        </div>
        <div className="tutor-icon">
          <img src="images/calcuus.png" alt="" />
        </div>
      </div>
      <div className="class-date-time">
        <ul>
          <li>
            10:00 AM-10:30 AM
          </li>
          <li>
            Tuesday, Aug 07
          </li>
        </ul>
      </div>
      <div className="tutor-item">
        <div className="tutor-wrap">
          <div className="tutor-avatar">
            <img src="images/avatar.jpg" alt="" />
          </div>
          <div className="tutor-subject">
            <h6>Sandra D.</h6>
            <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
            <p>Facilitator: Jane Doe | Session: Virtual</p>
            <Button variant="light" className="px-5 mt-3"> Time Left 14:55:00</Button>
          </div>
        </div>
        <div className="tutor-icon">
          <img src="images/geometry.png" alt="" />
        </div>
      </div>
      <div className="class-date-time">
        <ul>
          <li>
            12:00 PM-1:00PM
          </li>
          <li>
            Tuesday, Aug 07
          </li>
        </ul>
      </div>
      <div className="tutor-item">
        <div className="tutor-wrap">
          <div className="tutor-avatar">
            <img src="images/avatar-male.jpg" alt="" />
          </div>
          <div className="tutor-subject">
            <h6>David T.</h6>
            <h5>Geography Chapter 4: Cultural Geography</h5>
            <p>Facilitator: Jane Doe | Session: Virtual</p>
            <Button variant="light" className="px-5 mt-3"> Time Left 24:30:00</Button>
          </div>
        </div>
        <div className="tutor-icon">
          <img src="images/calcuus.png" alt="" />
        </div>
      </div> */}
    </div>
  </Col>
}