import { useEffect, useState } from "react"
import { Admin } from "../../Services/Admin"
import { NotificationManager } from "react-notifications"
import { School } from "../../Services/School"
import { Button, Col, Form, Row } from "react-bootstrap"
import FieldErrors from "../../Services/FieldErrors"
import { Student } from "../../Services/Student"
import { FecthData } from "../../Services/FetchData"

export default function UploadResource(){
    const user = JSON.parse(localStorage.getItem('user'))
    const [grade, setGrade] = useState([]);
    const [subject, setSubject] = useState([]);
    const [error, setError] = useState()
    const [formData, setFormData] = useState({
        user_id: user?._id,
        logo: null,
        title: '',
        grade_id: '',
        subject_id: '',
        description: '',
        category: '',
        audience: [],
        link: '',
        file: null
      })
      const submitForm = () => {
        const payload = new FormData()
        Object.entries(formData).map(([key, value]) => {
          payload.append(key, value)
        })
        Admin.postFormData(payload, 'users/create_resources').then(
          (data) => {
            if (data?.data) {
              NotificationManager.success(data?.message, 'Success!', 2000);
            } else {
              setError(data.errors.details)
              NotificationManager.error(data?.message, 'error!', 2000);
            }
          }
        )
      }
      useEffect(() => {
        Student.getDta({ page: 0 }, 'grades/get_all_grade').then(
          (data) => {
            setGrade(data?.data)
            setFormData({ ...formData, grade_id: data?.data[0]?._id })
          },
          (error) => setError(error.toString())
        );
        FecthData.getData('streem_and_subject/get_all_subject').then(
          (data) => {
            if (data.error === 0) {
              setSubject(data?.data)
              setFormData({ ...formData, subject_id: data?.data[0]?._id })
            }
          },
          (error) => {
            NotificationManager.error(error.toString(), 'error!', 2000);
            setError(error.toString())
          }
        )
      }, [])
      return <div className="card">
      <div className="school-side-details">
        <h5 className="text-muted">Upload Resources</h5>
        <Form>
          <Row>
            <Form.Group className="form-group my-3" as={Col} md="12">
              <div className="upload-resource">
                <img src={formData.logo ? URL.createObjectURL(formData.logo) : "images/camera-icon.png"} alt="" width="78" />
                <input type="file" onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
              </div>
              <FieldErrors errors={error} field="logo" />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control className="bg-grey" placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}>
              </Form.Control>
              <FieldErrors errors={error} field="title" />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="6">
              <Form.Select className="bg-grey" onChange={(e) => setFormData({ ...formData, grade_id: e.target.value })}>
                <option disabled selected>Select Grade</option>
                {grade.map(item => <option value={item._id}>{item.title}</option>)}
              </Form.Select>
              <FieldErrors errors={error} field="grade_id" />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="6">
              <Form.Select className="bg-grey" onChange={(e) => setFormData({ ...formData, subject_id: e.target.value })}>
                <option disabled selected>Select Subject</option>
                {subject?.map(sub => <option value={sub._id}>{sub.title}</option>)}
              </Form.Select>
              <FieldErrors errors={error} field="subject_id" />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Select placeholder='Select' className="bg-grey" onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                <option disabled selected>Select Category</option>
                <option value="E-Books">E-Book</option>
                <option value="Teaching Material">Teaching Material</option>
                <option value="Archived Classed">Archived Classed</option>
                <option value="Training">Training</option>
              </Form.Select>
              <FieldErrors errors={error} field="category" />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control as="select" className="bg-grey" multiple defaultValue={formData.audience} onChange={(e) => {
                var audience = [].slice.call(e.target.selectedOptions).map(item => item.value)
                setFormData({ ...formData, audience: audience })
              }} >
                <option disabled>Select Audience</option>
                <option value='global'>Global</option>
                <option value="student">Student</option>
                <option value="tutor">Tutor</option>
                {/* <option value="school">School</option>
                <option value="company">Company</option> */}
                <option value="facilitator">Facilitator</option>
                <option value="parent">Parent</option>
              </Form.Control>
              <FieldErrors errors={error} field="audience" />
            </Form.Group>
            {!formData?.file && <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control className="bg-grey" type="text" placeholder="Add link" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
              <FieldErrors errors={error} field="link" />
            </Form.Group>}
            <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control className="bg-grey" as="textarea" placeholder="Add Description" rows="4" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
              <FieldErrors errors={error} field="description" />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="12">
              <div className="attach-file-res mt-3">
                <img src="images/attach-file.png" alt="" width="100" />
                <br />
                <p>{formData?.file?.name}</p>
                <input type="file" onChange={(e) => setFormData({ ...formData, file: e.target.files[0] })} />
              </div>
              <FieldErrors errors={error} field="file" />
            </Form.Group>
            <Col md="12" className="text-center mt-2">
              <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={submitForm}>Upload</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
}