import { useEffect, useState } from "react"
import { Badge, Button, Col, Dropdown, Row } from "react-bootstrap"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { NotificationContainer, NotificationManager } from "react-notifications"
import { FecthData } from "../../Services/FetchData"
import { msToTime } from "../../helpers/utils"

export default function NotificationUI(){
	const user = JSON.parse(localStorage.getItem('user'))
	const [notifications,setNotifications]=useState([])
	
	const getNotificationURLPath=()=>{
		switch (user?.role) {
            case 'student':
			  return 'student/get_student_notification/'+ user?.student?._id
            case 'tutor':
              return 'tutor/get_tutor_notification/'+user?.tutor?._id
            case 'school':
              return 'school_user/get_school_user_notification/'+user?.school?._id
            case 'company':
              return 'company_user/get_company_user_notification/'+user?.company?._id
			case 'admin':
			  return 'users/get_admin_notification/'
			default:
			  return ''
          }

	}

	const getNotifications=()=>{
		const urlPath=getNotificationURLPath()
		if(urlPath){
			FecthData.getData(urlPath).then(
				(data)=> setNotifications(data?.data)
			)
		}
	}

	useEffect(()=>{
		getNotifications()
	},[])
    return <Dropdown className="hidden-arrow" drop="start">
        <DropdownToggle className="notification-bell" role="button"  as={'div'} bsPrefix='none'>
    <img src="images/bell-icon.png" alt="" width="32" />
    {notifications?.length>0&&<div className="noti-dot d-flex text-white text-sm justify-content-center">{notifications?.length}</div>}
        </DropdownToggle>
		{notifications?.length>0&&<Dropdown.Menu className="bg-light " style={{minWidth:'300px',maxWidth:'350px'}}>
      {notifications?.map((noti,index)=><>
		
	  <Dropdown.Item 
      // href="#/action"
      >
		
        {/* <Col md={2} ><img src="images/bell-icon.png" alt="" width="32" /></Col> */}
        <Col md={5}>{noti?.title} <p className="text-muted">{msToTime(new Date(noti?.createdAt))}</p></Col>
     
		{/* <div ></div>   <span className=" badge badge-pill badge-danger">3</span>  */}
		</Dropdown.Item>
		{index+1!=notifications?.length&&<Dropdown.Divider className="m-0"/>}
		</>
	)}
      {/* <Dropdown.Item className="text-sm"
      // href="#/action"
      >See all notifications</Dropdown.Item> */}
      {/* <Dropdown.Item 
      // href="#/action"
      >Logout</Dropdown.Item> */}
    </Dropdown.Menu>}
  </Dropdown>
}