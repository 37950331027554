import { Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FecthData } from "../../Services/FetchData";
import { NotificationManager } from "react-notifications";


function Video(props) {
    const user = JSON.parse(localStorage.getItem('user'))
    var callWindow= window.Daily
    const getMeetingToken=()=>{
        const requestData={
                room_name:props?.session?.room_name,
                user_id: `${user.role}_${user[user.role]._id}`,
                is_owner: user.role=='tutor',
        }
        FecthData.postJsonData(requestData,'common_api/generate_meeting_token').then(
            (data)=>{
            var token=data.data.token
            if(token){
                startCall(token)
            }else{
                NotificationManager.error('Unable to get meeting token!', 'error!', 2000);
            }
        })
    }
    const startCall = (token) => {
        // console.log(`${user.role}_${user[user.role]._id}`)
        var callFrame = callWindow.createFrame({
            showLeaveButton: true,
            showFullscreenButton: true,
            // userId:`${user.role}_${user[user.role]._id}`,
            layoutConfig: {
                grid: {
                  minTilesPerPage: 3, // default: 1, minimum required: 1
                  maxTilesPerPage: 36, // default: 25, maximum allowable: 49
                },
              },
            //   dailyConfig: {
            //     micAudioMode: 'music',
            //     participant: {
            //     user_id: `${user.role}_${user[user.role]._id}`}
            //   }
            //   participants:{
            //     user_id:`${user.role}_${user[user.role]._id}`,
            //   }
            //   onLeave: endCall
            // iframeStyle: {
            //   position: 'fixed',
            //   top: '0',
            //   left: '0',
            //   width: '100%',
            //   height: '100%',
            // },
            // enableKnocking: props.role=="tutor"?true:false
            // showShareButton : false
        });
                  
        if(window.Daily.getCallInstance()!=null){
            callFrame = window.Daily.getCallInstance()
        }  
        callFrame.join({ url: props.session.room_url,token:token })
        callFrame.on("left-meeting", (e) => {
            var session=callFrame.meetingSessionSummary()
            if(user.role=='tutor'){
                const requestData={
                    room_name:props?.session?.room_name,
                    session_id: session.id,
                }
            FecthData.postJsonData(requestData,'common_api/store_meeting').then(
                (data)=>{
                // var token=data.data.token
                
                // if(token){
                //     startCall(token)
                // }else{
                //     NotificationManager.error('Unable to get meeting token!', 'error!', 2000);
                // }
            })
            }
            callFrame.destroy()
        })
        callFrame.on('participant-joined',(e)=>{
            console.log(e)
        })
    }


    return <Link
    // to="/tutor-join-class"
    to="#"
     onClick={getMeetingToken} className="btn btn-success px-5 mt-3">Start Class Now</Link>


}

export default Video;