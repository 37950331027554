import _ from "lodash"
import { useEffect, useState } from "react"

export default function ActionButton({type,className,requiredFields,title}){
    const [isDisable,setIsDisable]=useState(false)
    useEffect(()=>{
        if(requiredFields){
            if(requiredFields.length==0){
                setIsDisable(true)
            }else{
                setIsDisable(_.find(requiredFields, field=>{return field==''})!=null)
            }
            
        }else{
            setIsDisable(true)
        }
    },[requiredFields])
    return <button type={type?type:'button'} className={className?className:"btn btn-primary btn-pill px-4"} disabled={isDisable} >{title?title:'Click'}</button>
}