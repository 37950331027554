import _ from "lodash"

export const staticData={
    years:{
        data: [
            {
                "value":"2020-2021", "label": "2020 - 2021"
            },
            {
                "value":"2021-2022", "label": "2021 - 2022"
            },
            {
                "value":"2022-2023", "label": "2022 - 2023"
            },
            {
                "value":"2023-2024", "label": "2023 - 2024"
            },
            {
                "value":"2024-2025", "label": "2024 - 2025"
            },
            {
                "value":"2025-2026", "label": "2025 - 2026"
            }
        ],
        getYears: () =>  staticData?.years?.data,
        getYearByValue: (yr) =>  _.find(staticData?.years?.data,{value:yr})
    },
    timezone:{
        EST:'-04:00',
        PST:'-07:00',
        IST:'+05:30',
        CST:'-05:00',
        getOffsetByTZ:(tz)=> staticData?.timezone[tz]
    },
    privacyText:{
        admin:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        school:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        company:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tutor:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        student:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        parent:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        facilitator:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        getPrivacyTextByUserType:(type)=> staticData?.privacyText?.[type]
    }
}