import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import config from '../../config';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FecthData } from '../../Services/FetchData';
import GraphUI from '../common/GraphUI';
import { getMaskedText, getWeekOfMonth } from '../../helpers/utils';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['WEEK 3', 'WEEK 4', 'WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4'];

const data = {
  labels,
  datasets: [
    {
      label: 'Lessons',
      data: ['15', '20', '30', '25', '32', '15'],
      borderColor: 'rgba(51, 64, 212, 0.5)',
      backgroundColor: 'rgba(51, 64, 212, 0.5)',
      fill: {
        target: 'origin',
        above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
        below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
      }
    },
    {
      label: 'Hours',
      data: ['18', '22', '24', '30', '28', '20'],
      borderColor: 'rgba(132, 176, 60, 0.4)',
      backgroundColor: 'rgba(132, 176, 60, 0.4)',
      fill: {
        target: 'origin',
        above: 'rgba(132, 176, 60, 0.4)',   // Area will be red above the origin
        below: 'rgba(132, 176, 60, 0.4)'    // And blue below the origin
      }
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function SchoolStudentProfile(props) {
  const student = props?.location?.state
  const user = JSON.parse(localStorage.getItem('user'))
  const [interval, setInterval] = useState('weekly')
  const [labels, setLabels] = useState(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'])
  const [datasets, setDatasets] = useState(data.datasets)
  const colorObj = {
    Algebra: '#914CE9',
    Chemistry: '#FEB41C',
    Physics: '#D0202C',
    Geometry: '#73913E',
    Biology: '#023e8a',
    Calculus: '#7b2cbf'

  }
  const [state, setState] = useState(data)
  const [statistics, setStatistics] = useState()
  const [school, setSchool] = useState()
  const history = useHistory()
  const selectedDay = val => {
    console.log(val);
  };
  const getSchool = () => {
    FecthData.getData('school/get_school_by_id/' + student?.school_id).then(
      (data) => setSchool(data?.data),
      (error) => {
        // setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  const getStudentProgress = () => {
    FecthData.getData('student/get_student_progress/' + student?._id + '?interval=' + interval).then(
      (data) => {
        var labels1 = interval == 'weekly' ? ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] : ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4', 'WEEK 5'];
        let arr1 = []
        data?.data.map(pr => {
          var obj = {
            label: pr?._id?.subject_name,
            data: interval == 'weekly' ? {
              MONDAY: new Date(pr?._id?.date).getDay() == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              TUESDAY: new Date(pr?._id?.date).getDay() == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              WEDNESDAY: new Date(pr?._id?.date).getDay() == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              THURSDAY: new Date(pr?._id?.date).getDay() == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
              FRIDAY: new Date(pr?._id?.date).getDay() == 5 ? (pr.total_hours / 60).toFixed(2) : 0,
              SATURDAY: new Date(pr?._id?.date).getDay() == 6 ? (pr.total_hours / 60).toFixed(2) : 0,
              SUNDAY: new Date(pr?._id?.date).getDay() == 7 ? (pr.total_hours / 60).toFixed(2) : 0,
            } : {
              'WEEK 1': getWeekOfMonth(pr?._id?.date) == 0 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 2': getWeekOfMonth(pr?._id?.date) == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 3': getWeekOfMonth(pr?._id?.date) == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 4': getWeekOfMonth(pr?._id?.date) == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 5': getWeekOfMonth(pr?._id?.date) == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
            },
            backgroundColor: [
              colorObj[`${pr?._id?.subject_name}`]
            ],
          }
          arr1.push(obj)
        })
        setLabels(labels1)
        setDatasets(arr1)
      }
    )
  }
  const getStudentStatistics = () => {
    FecthData.getData('student/get_student_progress_count/' + student?._id).then(
      (data) => {
        setStatistics(data?.data)

      }
    )
  }
  useEffect(() => {
    getStudentStatistics()
    getSchool()
  }, [])
  useEffect(() => {
    getStudentProgress()
  }, [interval])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Col md={12}>
              <Form.Group className="form-group mb-2">
                <Form.Control type="text" placeholder="Search" className="search-input" />
                <Button variant="primary" type="submit" className="search-btn">
                  <img src="images/search-icon.png" alt="" width="22" />
                </Button>
              </Form.Group>
            </Col>
            <div className="card">
              <Row>
                <Col md={9} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src={student?.gender_avatar ? getResourceUrl(student?.gender_avatar?.icon_image) : "images/avatar2.png"} width={'100%'} height={'100%'} alt="" />
                    </div>
                    <div className="ms-1">
                      <h4 className="text-muted mb-0">ID: {student?.student_code}</h4>
                      <h5 className="text-muted mb-0">Grade: {student?.grade_id?.title} | {school?.school_name}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={3} className="mt-2">
                  <div className="tutor-action">
                    <ul className="justify-content-end">
                      <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                      </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <GraphUI state={{ labels: labels, datasets: datasets }} statistics={statistics} setInterval={setInterval} />
                {/* <Col md={4}>
                  <div className="card bg-purple progress-box">
                    <div className="card-text text-white">
                      <img src="images/book-icon.png" alt="" width="58" />
                      <h4>Scheduled Classes</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">15</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-danger progress-box">
                    <div className="card-text text-white">
                      <img src="images/hat-icon.png" alt="" width="70" />
                      <h4>Number Of Students</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">07</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-success progress-box">
                    <div className="card-text text-white">
                      <img src="images/timer-icon.png" alt="" width="34" />
                      <h4>Time Spent On Dasya</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">14 <small>HRS</small></h5>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between align-items-center">Statistics
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Line options={options} data={data} />
                </Col> */}
              </Row>
            </div>
            {/* <div className="card">
              <Row>
                <Col md={12} className="mb-4">
                  <h6 className="text-muted mt-3">Student’s Ratings</h6>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div> */}
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={student?.gender_avatar ? getResourceUrl(student?.gender_avatar?.icon_image) : "images/avatar2.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="/school-students-profile">View Profile</Dropdown.Item>
                      <Dropdown.Divider /> */}
                      <Dropdown.Item ><Link className={'dropdown-item'} to={{ pathname: "/admin/user/edit", state: { ...student, user_role: 'student', status: student?.user_id.status, name: student?.user_id.name, user_sf_id: student?.user_id?._id } }}>Edit Account</Link></Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(student?.first_name+' '+student?.last_name,'name') } </h5>
                  <h6>Student ID: {student?.student_code}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${student?.phone}`}>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {student?.phone ? <span className='show-phone'>{getMaskedText(student?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${student?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${student?.user_id?.status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Student: {student?.user_id?.status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>{student?.about}
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(student?.email,'email')}</p>
                  <h4>Parent’s Email</h4>
                  <p>{getMaskedText(student?.guardian_email,'email')}</p>
                  <h4>Facilitator Email:</h4>
                  <p>{getMaskedText(student?.facilitator_email,'email')}</p>
                  <h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {student?.subjects?.map(subject => <li>{subject?.title}</li>)}
                      {/* <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default SchoolStudentProfile
