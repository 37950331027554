import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import config from '../../config';
import { FecthData } from '../../Services/FetchData';
import { Admin } from '../../Services/Admin';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import { NotificationManager } from 'react-notifications';
import FieldErrors from '../../Services/FieldErrors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function AdminResources() {
  const user = JSON.parse(localStorage.getItem('user'))

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modal, setModal] = useState(false);
  const handleClose1 = () => setModal(false);
  const handleShow1 = () => setModal(true);

  const [conf, setConf] = useState(false);
  const handleClose2 = () => setConf(false);
  const handleShow2 = () => setConf(true);

  const [state, setState] = useState([]);
  const [res, setRes] = useState([]);
  const [grade, setGrade] = useState([]);
  const [searchCat, setSearchCat] = useState('');
  const [subject, setSubject] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [error, setError] = useState()
  const history = useHistory();
  const [formData, setFormData] = useState({
    user_id: user?._id,
    logo: null,
    title: '',
    grade_id: '',
    subject_id: '',
    description: '',
    category: '',
    audience: [],
    link: '',
    file: null

  })

  const onChangeTabs = (searchCat, tabId) => {
    setSearchCat(searchCat)
    setSelectedTab(tabId)
  }
  const submitForm = () => {
    const payload = new FormData()
    Object.entries(formData).map(([key, value]) => {
      payload.append(key, value)
    })
    Admin.postFormData(payload, 'users/create_resources').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data?.message, 'success!', 2000);
          getResourceForApprove()
        } else {
          setError(data.errors.details)
          NotificationManager.error(data?.message, 'error!', 2000);
        }
      }
    )
  }
  const getResourceForApprove = () => {
    FecthData.getData('users/get_unapproved_resources').then(
      (data) => setRes(data.data)
    )
  }
  const approveResource = (resource) => {
    const body = { resource_id: resource._id, approval_status: true, approved_by: user?._id }
    FecthData.postJsonData(body, 'users/resource_approval').then(
      (data) => {

        getResourceForApprove()
      }
    )
  }
  useEffect(() => {
    Student.getDta({ page: 0 }, 'grades/get_all_grade').then(
      (data) => setGrade(data.data),
      (error) => setError(error.toString())
    );
    FecthData.getData('streem_and_subject/get_all_subject').then(
      (data) => {
        if (data.error === 0) {
          setSubject(data?.data)
        }
      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
    getResourceForApprove()
  }, [])
  useEffect(() => {
    FecthData.getData('users/get_resources?category=' + searchCat).then(
      (data) => setState(data.data)
    )
  }, [searchCat])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }


  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              {/* <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                <div className="mt-4">
                  <h6 className="text-muted mt-3">K12 Lesson Plan & Curriculum</h6>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box1.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  {/* <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a> *
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" onClick={setModal}>Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box2.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Expressions & Equations</h4>
                    <p>1:00 min</p>
                  </div>
                  {/* <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a> *
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" onClick={setModal}>Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box3.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Statistics & Probability</h4>
                    <p>1:30 min</p>
                  </div>
                  {/* <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a> *
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" onClick={setModal}>Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={12}>
                <div className="video-category">
                  <ul>
                    <li className={selectedTab == 1 ? "active" : ''} onClick={() => onChangeTabs('', 1)}>
                      <a href="javascript:void(0)">Upload Request</a>
                    </li>
                    <li className={selectedTab == 2 ? "active" : ''} onClick={() => onChangeTabs('E-Books', 2)}>
                      <a href="javascript:void(0)" eventKey="first">E-Books</a>
                      {/* <Link ></Link> *
                    </li>
                    <li className={selectedTab == 3 ? "active" : ''} onClick={() => onChangeTabs('Teaching Material', 3)}>
                      <a href="javascript:void(0)">Teaching Material</a>
                    </li>
                    <li className={selectedTab == 4 ? "active" : ''} onClick={() => onChangeTabs('Archived Classed', 4)}>
                      <a href="javascript:void(0)">Archived Classed</a>
                    </li>
                    <li className={selectedTab == 5 ? "active" : ''} onClick={() => onChangeTabs('Training', 5)}>
                      <a href="javascript:void(0)">Training</a>
                    </li>
                    <li className={selectedTab == 6 ? "active" : ''} onClick={() => onChangeTabs('Favorites', 6)}>
                      <a href="javascript:void(0)">Favorites</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={12}>
                <h6 className="text-muted mt-2">Featured Videos</h6>
              </Col>
              {
                state.map(item => <Col md={3}>
                  <div className="video-box">
                    <img src={item.image ? item.image : "images/video1.jpg"} alt="" />
                    <div className="video-box-text">
                      <h4>{item.title}</h4>
                      <p>1:30 min</p>
                    </div>
                    <a href={item.file ? 'resources/' + item.file : 'javascript:void(0)'}
                      target={item.file ? "_blank" : null} className="watch-btn">
                      <img src="images/play-icon.png" alt="" />
                    </a>
                    <Dropdown className="watch-btn-option">
                      <Dropdown.Toggle>
                        <img src="images/three-dot.png" alt="" width="20" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={setModal} >Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={setConf}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>)
              } */}
              {/* <Col md={3}>approveResource
                <div className="video-box">
                  <img src="images/video1.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video2.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video3.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video4.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>*/}
              {/* <Col md={12}>
                <h6 className="text-muted mt-2">Most Viewed</h6>
              </Col>
              {
                state.map(item => <Col md={3}>
                  <div className="video-box">
                    <img src={item.image ? item.image : "images/video1.jpg"} alt="" />
                    <div className="video-box-text">
                      <h4>{item.title}</h4>
                      <p>1:30 min</p>
                    </div>
                    <a href={item.file ? 'resources/' + item.file : 'javascript:void(0)'}
                      target={item.file ? "_blank" : null} className="watch-btn">
                      <img src="images/play-icon.png" alt="" />
                    </a>
                    <Dropdown className="watch-btn-option">
                      <Dropdown.Toggle>
                        <img src="images/three-dot.png" alt="" width="20" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={setModal} >Edit</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={setConf}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>)
              } */}
              {/* <Col md={3}>
                <div className="video-box">
                  <img src="images/video1.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video2.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video3.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video4.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="#" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="#" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col> */}
              <Col md={12}>
                <h4>Unapproved Resource</h4>
                <div className="table-responsive">
                  <table title='Unapproved Resource' className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Title</th>
                        <th scope="col">Category</th>
                        <th scope="col">File</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {res.length > 0 ? <>{res.map(item => <tr>
                        <td><img src={getResourceUrl(item.image)} alt="" width="44" className="rounded me-2" /></td>
                        <td>{item.title}</td>
                        <td>{item?.category}</td>
                        <td><a href={item.file ? getResourceUrl(item.file) : 'javascript:void(0)'}
                          target={item.file ? "_blank" : null} className="watch-btn">View File
                        </a></td>
                        <td><Button onClick={() => approveResource(item)}>Approve</Button>{item?.approval}</td>
                      </tr>)}</> : <tr>No Resource found</tr>}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <div className="card">
              <div className="school-side-details">
                <h5 className="text-muted">Upload Resources</h5>
                <Form>
                  <Row>
                    <Form.Group className="form-group my-3" as={Col} md="12">
                      <div className="upload-resource">
                        <img src={formData.logo ? URL.createObjectURL(formData.logo) : "images/camera-icon.png"} alt="" width="78" />
                        <input type="file" onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })} />
                      </div>
                      <FieldErrors errors={error} field="logo" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}>
                      </Form.Control>
                      <FieldErrors errors={error} field="title" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey" onChange={(e) => setFormData({ ...formData, grade_id: e.target.value })}>
                        <option disabled selected>Select Grade</option>
                        {grade.map(item => <option value={item._id}>{item.title}</option>)}
                      </Form.Select>
                      <FieldErrors errors={error} field="grade_id" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey" onChange={(e) => setFormData({ ...formData, subject_id: e.target.value })}>
                        <option disabled selected>Select Subject</option>
                        {subject?.map(sub => <option value={sub._id}>{sub.title}</option>)}
                      </Form.Select>
                      <FieldErrors errors={error} field="subject_id" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Select placeholder='Select' className="bg-grey" onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                        <option disabled selected>Select Category</option>
                        <option value="E-Books">E-Book</option>
                        <option value="Teaching Material">Teaching Material</option>
                        <option value="Archived Classed">Archived Classed</option>
                        <option value="Training">Training</option>
                      </Form.Select>
                      <FieldErrors errors={error} field="category" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control as="select" className="bg-grey" multiple defaultValue={formData.audience} onChange={(e) => {
                        var audience = [].slice.call(e.target.selectedOptions).map(item => item.value)
                        setFormData({ ...formData, audience: audience })
                      }} >
                        <option disabled>Select Audience</option>
                        <option value='admin'>Admin</option>
                        <option value="student">Student</option>
                        <option value="tutor">Tutor</option>
                        <option value="school">School</option>
                        <option value="company">Company</option>
                        <option value="facilitator">Facilitator</option>
                        <option value="parent">Parent</option>
                      </Form.Control>
                      <FieldErrors errors={error} field="audience" />
                    </Form.Group>
                    {!formData?.file && <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" type="text" placeholder="Add link"
                        value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} />
                      <FieldErrors errors={error} field="link" />
                    </Form.Group>}
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" as="textarea" placeholder="Add Description" rows="4"
                        value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                      <FieldErrors errors={error} field="description" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <div className="attach-file-res mt-3">
                        <img src="images/attach-file.png" alt="" width="100" />
                        <br />
                        <p>{formData?.file?.name}</p>

                        <input type="file" onChange={(e) => setFormData({ ...formData, file: e.target.files[0] })} />
                      </div>
                      <FieldErrors errors={error} field="file" />
                    </Form.Group>
                    <Col md="12" className="text-center mt-2">
                      <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={submitForm}>Upload</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Request By ID: 778987 </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <div className="row justify-content-center">
                  <Form.Group className="form-group mb-3" as={Col} md="6">
                    <img className="img-fluid rounded" src={formData.logo ? URL.createObjectURL(formData.logo) : "images/dash-box2.jpg"} alt="" />
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control className="bg-grey" placeholder='Title' value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })}>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="8th Grade" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Algebra" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={formData.category} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Students /Mentees" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="email" value="loremipsum@dolores.com" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control as="textarea" value={formData.description} rows="4"
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill me-2" variant="primary" onClick={submitForm}>Upload Video</Button>
                  <Button className="px-4 btn-pill ms-2" variant="secondary" onClick={() => setFormData({ ...formData, title: '', category: 'E-Book', logo: null, description: '' })}>Delete Video</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={modal} onHide={handleClose1}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Resources</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <div className="row justify-content-center">
                  <Form.Group className="form-group mb-3" as={Col} md="6">
                    <img className="img-fluid rounded" src="images/dash-box2.jpg" alt="" />
                  </Form.Group>
                </div>
                <div className="clearfix"></div>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="8th Grade" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Algebra" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="E-Book" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Students /Mentees" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="email" value="loremipsum@dolores.com" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control as="textarea" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
eiusmod tempor incididunt ut labore et dolore magna aliqua. 
Ut enim ad minim veniam, quis nostrud exercitation ullamco 
laboris nisi ut aliquip ex ea commodo consequat. " rows="4" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <div className="attach-file-res">
                    <img src="images/attach-file.png" alt="" width="100" />
                    <input type="file" />
                  </div>
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill me-2" variant="primary">Save Changes</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={conf} onHide={handleClose2}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <img src="/images/confirm-icon.png" alt="" width="50" />
          </div>
          <p className="modal-text text-center">
            Are you sure you want to delete this resource?
          </p>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose2} className="px-5 btn-pill">
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AdminResources
