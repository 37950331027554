import React, { useState } from 'react'
import {
  Container,
  Modal,
  Col,
  Button,
  Form,
  FormControl,
  Offcanvas
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

function SideMenu() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="left">
        <div className="dash-logo">
          <Link to="/dashboard">
            <img src="images/logo.png" alt="" width="220" />
          </Link>
          <a href="javascript:void(0)" onClick={handleShow} className="burger-menu">
            <img src="images/menu.png" alt="" width="60" />
          </a>
          <Offcanvas show={show} onHide={handleClose}>
            {/* <Offcanvas.Header closeButton>
              <Offcanvas.Title>DASYA</Offcanvas.Title>
            </Offcanvas.Header> */}
            <Offcanvas.Body>
              <div className="user-short-text user-short-text-mobile">
                <div className="notification-bell">
                  <img src="images/bell-icon.png" alt="" width="32" />
                  <div className="noti-dot"></div>
                </div>
                <div className="user-short-profile">
                  <div className="user-avatar">
                    <img src="images/avatar2.png" alt="" />
                  </div>
                  <div className="user-short-name">
                    <h5>{user?.name} | ID: 567876</h5>
                    <p>Academic Year {user?.student?.academic_year}</p>
                  </div>
                  <div className="drop-down">
                    <img src="images/drop-icon.png" alt="" width="10" />
                  </div>
                </div>
              </div>
              <div className="dash-menu mobile-side-menu">
                <ul>
                  <li>
                    <NavLink activeClassName='active' to="/dashboard"><span style={{ backgroundImage: 'url("images/dash-icon1.png")' }}></span> Dashboard</NavLink >
                  </li>
                  <li>
                    <NavLink activeClassName='active' to="/my-schedule"><span style={{ backgroundImage: 'url("images/dash-icon2.png")' }}></span> My Schedule</NavLink >
                  </li>
                  {/* <li>
                    <NavLink activeClassName='active' to="/chats"><span style={{ backgroundImage: 'url("images/dash-icon3.png")' }}></span> Chats</NavLink>
                  </li> */}
                  <li>
                    <NavLink activeClassName='active' to="/my-progress"><span style={{ backgroundImage: 'url("images/dash-icon4.png")' }}></span> Progress</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' to="/resources"><span style={{ backgroundImage: 'url("images/dash-icon5.png")' }}></span> Resources</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName='active' to="/settings"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Settings</NavLink>
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="mobile-toggle">
          <img src="images/menu.png" alt="" width="40" />
        </div>
        <div className="dash-menu">
          <ul>
            <li>
              <NavLink activeClassName='active' to="/dashboard"><span style={{ backgroundImage: 'url("images/dash-icon1.png")' }}></span> Dashboard</NavLink >
            </li>
            <li>
              <NavLink activeClassName='active' to="/my-schedule"><span style={{ backgroundImage: 'url("images/dash-icon2.png")' }}></span> My Schedule</NavLink >
            </li>
            {/* <li>
              <NavLink activeClassName='active' to="/chats"><span style={{ backgroundImage: 'url("images/dash-icon3.png")' }}></span> Chats</NavLink>
            </li> */}
            <li>
              <NavLink activeClassName='active' to="/my-progress"><span style={{ backgroundImage: 'url("images/dash-icon4.png")' }}></span> Progress</NavLink>
            </li>
            <li>
              <NavLink activeClassName='active' to="/resources"><span style={{ backgroundImage: 'url("images/dash-icon5.png")' }}></span> Resources</NavLink>
            </li>
            <li>
              <NavLink activeClassName='active' to="/settings"><span style={{ backgroundImage: 'url("images/dash-icon6.png")' }}></span> Settings</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default SideMenu
