import React, { useState } from 'react'
import {
  Container,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useHistory } from "react-router-dom";
import { Guardian } from '../../Services/Guardian';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';
import { setAppState } from '../../helpers/app_state';
import { staticData } from '../../helpers/data';

const formatDate = (date) => {

  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}
function ParSignupStepFour({ values, prevStep, handleSchool }) {
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  let history = useHistory();
  const submitFormData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (values.accept_privacy_policy) {
      let formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        formData.append(key, value);
      })
      Guardian.registerGuardian(formData).then(
        (data) => {
          if (!data.errors) {
            setAppState(data)
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            history.push('/parents-dashboard');
          } else {
            NotificationManager.error(data.message, 'error!', 2000);
            setMessage(data.message)
          }


        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      NotificationManager.error('Please accept privacy policy', 'error!', 2000);
    }

  };
  const handleChange = event => {
    setIsChecked(current => !current);
  };
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Privacy Notice</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>Privacy Notice</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">
                        <Row>
                          <Col md={12}>
                            <h6 className="text-muted">Privacy notice</h6>
                            <p className="text-muted">
                              {staticData?.privacyText?.parent}
                              </p>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                            </p>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="checkbox" name="accept_privacy_policy" value={values.accept_privacy_policy} onChange={(e) => handleSchool(e.target.name, e.target.checked)} label={`I have read & accepted the Privacy Notice as on ${formatDate()}`} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} requiredFields={[values.accept_privacy_policy ? values.accept_privacy_policy : '']} title={'Continue'} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default ParSignupStepFour
