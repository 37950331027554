import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AdminLayout from "./Dashboard/AdminLayout";
import { Organization } from "../../Services/Organization";
import { Link } from "react-router-dom";
import { FecthData } from "../../Services/FetchData";
import Autocomplete from "react-autocomplete";
import { NotificationManager } from "react-notifications";
import ActionButton from "../ActionButton";
import FieldErrors from "../../Services/FieldErrors";
import { getResourceUrl } from "../../Services/CommanFunctions";

export default function AdminOrganization() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setOrgFormData({ ...orgFormData, logo: null })
    setValue('')
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const handleOrgModalClose = () => {
    setOrgFormData({ ...orgFormData, logo: null })
    setShowOrgModal(false);
  }
  const handleOrgModalShow = () => setShowOrgModal(true);
  const [value, setValue] = useState('');
  const [company, setCompany] = useState(null)
  const [companies, setCompanies] = useState([])
  const [selectedCompany, selectCompany] = useState()
  const [companyUser, setCompanyUser] = useState()
  const [error, setError] = useState(null)
  const user = JSON.parse(localStorage.getItem('user'))
  const [filter, setFilter] = useState('')

  const [orgFormData, setOrgFormData] = useState({
    company_code: "",
    company_name: "",
    company_ICO: "",
    company_address_line1: "",
    company_address_line2: "",
    company_city: "",
    company_state: "",
    company_country: "",
    company_zip: "",
    created_by: user?._id,
    logo: null
  })
  // const [orgFormData, setOrgFormData] = useState({
  //   company: "",
  //   company_id: "",
  //   company_name: "",
  //   company_code: "",
  //   company_address: "",
  //   company_city: "",
  //   company_state: "",
  //   company_zip: "",
  //   logo: "",
  //   display_logo: "yes",
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   department: "",
  //   contact_number: "",
  //   contact_by: [],
  //   accept_privacy_policy: false
  // })
  const setOrgInfo = (item) => {
    setOrgFormData({
      ...orgFormData,
      company: item,
      company_list_id: item._id,
      company_name: item.company_name,
      company_code: item.company_code,
      company_address: item.company_address_line1,
      company_city: item.company_city,
      company_state: item.company_state,
      company_zip: item.company_zip
    })
  }
  const submitOrgData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      let formData = new FormData();
      Object.entries(orgFormData).map(([key, value]) => {
        formData.append(key, value);
      })
      FecthData.post(formData, 'company/create').then(
        (data) => {
          if (!data.errors) {
            NotificationManager.success(data.message, 'success!', 2000);
            handleClose()
            fetchCompanies()
            // setMessage(data.message)
            // history.push('/school');
          } else {
            NotificationManager.error(data.message.toString(), 'error!', 2000);
          }


        },
        (errors) => {
          // NotificationManager.error(data.message.toString(), 'error!', 2000);
          // setError(error.toString())
        }
      )
    }

  }
  const submitData = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      let formData = new FormData();
      Object.entries(orgFormData).map(([key, value]) => {
        if (key !== 'contact_by') {
          formData.append(key, value);
        } else {
          formData.append(key, value.toString());
        }

      })
      Organization.registerCompany(formData).then(
        (data) => {
          if (!data.errors) {
            NotificationManager.success(data.message, 'success!', 2000);
            // setMessage(data.message)
            // history.push('/school');
            handleOrgModalClose()
          } else {
            NotificationManager.error(data.message.toString(), 'error!', 2000);
          }


        },
        (errors) => {
          // NotificationManager.error(data.message.toString(), 'error!', 2000);
          // setError(error.toString())
        }
      )
    }

  }

  const getAllCopanies = (search) => {
    FecthData.getData('company/get_company_list?search=' + search).then(
      (data) => {

        setCompanies(data?.data)
        // selectCompany(data?.data[0])
      },
      (error) => setError(error.toString())
    ).catch(e => console.log(e))
  }
  const fetchCompanies = () => {
    FecthData.getData('company/get_all_company?is_registered=' + true + '&root_company=' + false + '&filter=' + filter).then(
      (data) => {
        setCompany(data?.data)
        // selectCompany(data?.data[0])
      },
      (error) => setError(error.toString())
    )
  }
  useEffect(() => {
    if (value.length >= 3 || !value)
      getAllCopanies(value)
  }, [value])
  useEffect(() => {
    fetchCompanies()
  }, [filter])
  const fetchCompanyUser = () => {
    if (selectedCompany) {
      FecthData.getData('company_user/get_company_user_by_company_id/' + selectedCompany._id).then(
        (data) => setCompanyUser(data?.data)
      )
    }
  }
  useEffect(() => {
    fetchCompanyUser()
  }, [selectedCompany])
  return <AdminLayout company={selectedCompany} refreshCard={fetchCompanyUser} companyUser={companyUser} userDetailPath='/admin/company/tutor/' showUserCard={selectedCompany}>
    <Row>
      <Col md={12}>
        <Form.Group className="form-group mb-2">
          <Form.Control type="text" placeholder="Search" className="search-input" />
          <Button variant="primary" type="submit" className="search-btn">
            <img src="images/search-icon.png" alt="" width="22" />
          </Button>
        </Form.Group>
      </Col>
      <Col md={6} className="mt-4">
        <h3 className="text-muted mb-0">Companies</h3>
      </Col>
      <Col md={6} className="mt-4">
        <div className="filters">
          <ul>
            <li>
              {/* <a href="javascript:void(0)" className='btn-primary'>Sort <img src="images/sort-icon.png" alt="" width="17" />
              </a> */}
              <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                <option selected disabled>Sort</option>
                <option value={'ASC'}>Alphabetic A-Z</option>
                <option value={'DESC'}>Alphabetic Z-A</option>
              </Form.Select>
            </li>
            <li>
              {/* <a href="javascript:void(0)" className='btn-primary'>Filter <img src="images/filter-icon.png" alt="" width="13" />
              </a> */}
              <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                <option selected disabled>Filter</option>
                <option value={'Last Modified'}>Last Modified</option>
                <option value={'Oldest'}>Oldest</option>
                <option value={'Newest'}>Newest</option>
              </Form.Select>
            </li>
            <li onClick={handleShow}>
              <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
              </a>
            </li>
          </ul>
        </div>
      </Col>
      {company && company?.length > 0 && company?.map((content, index) => (
        <Col md={3} key={index} onClick={() => selectCompany(content)}>
          <div className="school-box">
            <Link to='#'>
              <div className="school-box-logo">
                <img src={content?.logo ? getResourceUrl(content.logo) : "images/school-logo3.png"} alt="" />
              </div>
              <h4>{content.company_name}</h4>
            </Link>
          </div>
        </Col>
      ))}
      {!company && <Col md={12} >No company Found</Col>}
    </Row>
    <Modal show={showOrgModal} onHide={handleOrgModalClose}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitOrgData}>
          <div className="modal-form">
            <Row>
              {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
              {/* <Form.Group className="form-group mb-3" style={{ zIndex: 2 }}>
                  {/* <Form.Control type="text" placeholder="Search" /> 
                  <Autocomplete className="search"
                    items={school || []}
                    shouldItemRender={(item, value
                    ) => item.school_name.toLowerCase()
                      .indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item.school_name}
                    renderItem={(item, isHighlighted) =>
                      <div style={{
                        background: isHighlighted ?
                          '#eee' : 'white',
                        padding: '5px 10px',
                      }}
                        key={item.id}>
                        {item.school_name}
                      </div>
                    }
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onSelect={(val, item) => {
                      setValue(val)
                      setSchoolInfo(item)
                    }}
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#E9ECF4',
                        // boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                        width: '100%',
                        color: '#000',
                        borderRadius: 10,
                        minHeight: 52,
                        border: '1px solid #E9ECF4',
                        fontSize: 14,
                        paddingLeft: 15,
                        paddingRight: 15,
                      },
                      placeholder: 'Search by school name, city or zip code'
                    }}
                    wrapperStyle={{
                      width: '100%',
                    }}
                  />
                  <Button variant="primary" type="submit" className="search-btn" style={{ right: 18 }}>
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group> */}
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Company Name" value={orgFormData.company_name} onChange={(e) => setOrgFormData({ ...orgFormData, company_name: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Company Code" value={orgFormData.company_code} onChange={(e) => setOrgFormData({ ...orgFormData, company_code: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Company ICO*" value={orgFormData.company_ICO} onChange={(e) => setOrgFormData({ ...orgFormData, company_ICO: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Address 1*" value={orgFormData.company_address_line1} onChange={(e) => setOrgFormData({ ...orgFormData, company_address_line1: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Address 2" value={orgFormData.company_address_line2} onChange={(e) => setOrgFormData({ ...orgFormData, company_address_line2: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_city} placeholder="City*" onChange={(e) => setOrgFormData({ ...orgFormData, company_city: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_state} placeholder="State*" onChange={(e) => setOrgFormData({ ...orgFormData, company_state: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_country} placeholder="Country*" onChange={(e) => setOrgFormData({ ...orgFormData, company_country: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_zip} placeholder="Zip Code*" onChange={(e) => setOrgFormData({ ...orgFormData, company_zip: e.target.value })} />
              </Form.Group>
              <Col md="12" className="text-center mt-2">
                <ActionButton title={'Send Request'} type={'submit'} requiredFields={[orgFormData.company_name, orgFormData.company_code, orgFormData.company_ICO, orgFormData.company_address_line1, orgFormData.company_city, orgFormData.company_state, orgFormData.company_country, orgFormData.company_zip]} />
                {/* <Button className="px-4 btn-pill" variant="primary" onClick={submitData}>Send Request</Button> */}
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
    <Modal show={show} onHide={handleClose}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitOrgData}>
          <div className="modal-form">
            <Row>
              {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
              <Form.Group className="form-group mb-3">
                <div className="upload-resource">
                  <img src={orgFormData.logo ? URL.createObjectURL(orgFormData.logo) : "images/camera-icon.png"} alt="" width="78" />
                  <input type="file" onChange={(e) => setOrgFormData({ ...orgFormData, logo: e.target.files[0] })} />
                </div>
                <FieldErrors errors={error} field="logo" />
              </Form.Group>
              <Form.Group className="form-group mb-3" style={{ zIndex: 2 }}>
                {/* <Form.Control type="text" placeholder="Search" /> */}
                <Autocomplete className="search"
                  items={companies || []}
                  shouldItemRender={(item, value
                  ) => item?.company_name.toLowerCase()
                    .indexOf(value.toLowerCase()) > -1}
                  getItemValue={item => item?.company_name}
                  renderItem={(item, isHighlighted) =>
                    <div style={{
                      background: isHighlighted ?
                        '#eee' : 'white',
                      padding: '5px 10px',
                    }}
                      {...item} key={item._id}>
                      {item?.company_name}
                    </div>
                  }
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value)
                    // handleFormData(e)
                  }}
                  onSelect={(val, item) => {
                    setValue(val)
                    setOrgInfo(item)
                    // handleCompany('company', item)
                    // handleCompany('company_id', item._id)
                    // handleCompany('company_name', item.company_name)
                    // handleCompany('company_ein', item.company_code)
                    // handleCompany('company_address', item.company_address_line1)
                    // handleCompany('company_city', item.company_city)
                    // handleCompany('company_state', item.company_state)
                    // handleCompany('company_zip', item.company_zip)

                  }}
                  inputProps={{
                    style: {
                      width: '100%',
                      background: '#fff',
                      boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                      width: '100%',
                      color: '#000',
                      borderRadius: 10,
                      minHeight: 52,
                      border: '1px solid #E9ECF4',
                      fontSize: 14,
                      paddingLeft: 15,
                      paddingRight: 15,
                    },
                    name: 'company',
                    placeholder: 'Search by company name(Enter at least 3 word)',
                    required: true
                  }}
                  wrapperStyle={{
                    width: '100%',
                  }}
                />
                <Button variant="primary" type="button" className="search-btn" style={{ right: 18 }}>
                  <img src="images/search-icon.png" alt="" width="22" />
                </Button>
              </Form.Group>
              {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Point of Contact First Name*" value={orgFormData.first_name} onChange={(e) => setOrgFormData({ ...orgFormData, first_name: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Point of Contact Last Name*" value={orgFormData.last_name} onChange={(e) => setOrgFormData({ ...orgFormData, last_name: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Contact number*" value={orgFormData.contact_number} onChange={(e) => setOrgFormData({ ...orgFormData, contact_number: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="email" placeholder="Email*" value={orgFormData.email} onChange={(e) => setOrgFormData({ ...orgFormData, email: e.target.value })} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Department*" value={orgFormData.department} onChange={(e) => setOrgFormData({ ...orgFormData, department: e.target.value })} />
              </Form.Group> */}
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" placeholder="Company Name" value={orgFormData.company_name} />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_code} placeholder="Company Code" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_address} placeholder="Address" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_city} placeholder="City" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_state} placeholder="State" />
              </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={orgFormData.company_zip} placeholder="Zip Code" />
              </Form.Group>
              <Col md="12" className="text-center mt-2">
                <ActionButton title={'Add company'} type={'submit'} requiredFields={[orgFormData.company_id, orgFormData.first_name, orgFormData.last_name, orgFormData.contact_number, orgFormData.email, orgFormData.department]} />
                {/* <Button className="px-4 btn-pill" variant="primary">Send Request</Button> */}
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  </AdminLayout>
}